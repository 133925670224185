// ----------------------------------------------------
// ----------------- SETTINGS: WIDTHS -----------------
// ----------------------------------------------------

$width-map: (
	20: 20%,
	25: 25%,
	30: 30%,
	33: 33.3%,
	40: 40%,
	50: 50%,
	60: 60%,
	66: 66.6%,
	70: 70%,
	75: 75%,
	80: 80%,
	100: 100%
);


@each $name, $value in $width-map {
	.w-#{$name} {
		width: $value !important;
	}
}
@media (min-width: 576px) {
	@each $name, $value in $width-map {
		.w-sm-#{$name} {
			width: $value !important;
		}
	}
}
@media (min-width: 768px) {
	@each $name, $value in $width-map {
		.w-md-#{$name} {
			width: $value !important;
		}
	}
}
@media (min-width: 992px) {
	@each $name, $value in $width-map {
		.w-lg-#{$name} {
			width: $value !important;
		}
	}
}
@media (min-width: 1200px) {
	@each $name, $value in $width-map {
		.w-xl-#{$name} {
			width: $value !important;
		}
	}
}

// ----------------------------------------------------
// ------------ SETTINGS: VIEWPORT-HEIGHT -------------
// ----------------------------------------------------
$vheight-map: (
	30: 30vh,
	50: 50vh,
	75: 75vh,
	100: 100vh
);

@each $name, $value in $vheight-map {
	.vh-#{$name} {
		height: $value !important;
	}
}
@media (min-width: 576px) {
	@each $name, $value in $vheight-map {
		.vh-sm-#{$name} {
			height: $value !important;
		}
	}
}
@media (min-width: 768px) {
	@each $name, $value in $vheight-map {
		.vh-md-#{$name} {
			height: $value !important;
		}
	}
}
@media (min-width: 992px) {
	@each $name, $value in $vheight-map {
		.vh-lg-#{$name} {
			height: $value !important;
		}
	}
}
@media (min-width: 1200px) {
	@each $name, $value in $vheight-map {
		.vh-xl-#{$name} {
			height: $value !important;
		}
	}
}

// ----------------------------------------------------
// ------------- SETTINGS: MIN-HEIGHT: PX -------------
// ----------------------------------------------------

$height-map: (
	200: 200px,
	250: 250px,
	300: 300px,
	350: 350px,
	400: 400px,
	450: 450px,
	500: 500px,
	550: 550px,
	600: 600px,
	650: 650px,
	700: 700px,
	750: 750px,
	800: 800px
);

@each $name, $value in $height-map {
	.min-h-#{$name} {
		height: $value !important;
	}
}
@media (min-width: 576px) {
	@each $name, $value in $height-map {
		.min-h-sm-#{$name} {
			height: $value !important;
		}
	}
}
@media (min-width: 768px) {
	@each $name, $value in $height-map {
		.min-h-md-#{$name} {
			height: $value !important;
		}
	}
}
@media (min-width: 992px) {
	@each $name, $value in $height-map {
		.min-h-lg-#{$name} {
			height: $value !important;
		}
	}
}
@media (min-width: 1200px) {
	@each $name, $value in $height-map {
		.min-h-xl-#{$name} {
			height: $value !important;
		}
	}
}

// ----------------------------------------------------
// ----- SETTINGS: HEADER-HEIGHT + FIRST ARTICLE ------
// ----------------------------------------------------
$height    : 0px;
$height-sm : 0px;
$height-md : 0px;
$height-lg : 0px;
$height-xl : 0px;

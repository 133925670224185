// ----------------------------------------------------
// ---------------- CONTAINER-SETTINGS ----------------
// ----------------------------------------------------
$container : 1280px;

@media (min-width: 1200px) {
	.container,
	.container-lg,
	.container-md,
	.container-sm,
	.container-xl {
		max-width: $container;
	}
}


.container90 {
	@media (min-width: 992px) {
		.container {
			max-width: 100%;
		}
	}
	@media (min-width: 1200px) {
		.container {
			max-width: 90%;
		}
	}
}

.container80 {
	@media (min-width: 992px) {
		.container {
			max-width: 100%;
		}
	}
	@media (min-width: 1200px) {
		.container {
			max-width: 80%;
			padding: 0px;
		}
	}
}


// ----------------------------------------------------
// ----------------- FIXED CONTAINER ------------------
// ----------------------------------------------------

.fixed-lg {
    > .container {
        max-width: 800px;
    }
}

@media (max-width: 991px) {
	.fixed-sm {
		> .container {
			max-width: 550px;
		}
	}
}
@media (max-width: 768px) {
	.fixed-xs {
		> .container {
			max-width: 550px;
		}
	}
}


// check http://scg.ar-ch.org/5b4140
$gray1 : #130E0D;
$gray2 : lighten($gray1,7%);
$gray3 : lighten($gray1,14%);
$gray4 : lighten($gray1,21%);
$gray5 : lighten($gray1,28%);
$gray6 : lighten($gray1,35%);
$gray7 : lighten($gray1,42%);
$gray8 : lighten($gray1,49%);
$gray9 : lighten($gray1,56%);
$gray10: lighten($gray1,63%);

$lightgray1: lighten($gray1,70%);
$lightgray2: lighten($gray1,76%);
$lightgray3: lighten($gray1,82%);
$lightgray4: lighten($gray1,87%);

$color1: #5c9304; // hellblau
$color2: #4b2a26; // braun
$color3: #47555a; // dunkelblau
$color4: #007c5b; // grün

$color-information:     #7091b8;
$color-confirmation:    #67B04F;
$color-warning:         #D93600;
$color-blockquote:      $gray6;

$red:     #dc3545 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;


$primary:       $color1 !default;
$primaryhover:  $color3 !default;

$secondary:     $color2 !default;
$secondaryhover:$color3 !default;

$success:       $green !default;
$successhover:  $green !default;

$info:          $gray6 !default;
$infohover:     $gray8 !default;

$warning:       $yellow !default;
$warninghover:  $yellow !default;

$danger:        $red !default;
$dangerhover:   darken($danger, 15%) !default;

$light:         $lightgray4 !default;
$lighthover:    $lightgray3 !default;

$medium:        $gray9 !default;
$mediumhover:   $gray10 !default;

$dark:          $gray4 !default;
$darkhover:     $gray5 !default;


$cathegory: (
	transparent: (transparent, $light),
	primary: ($primary, $primaryhover),
	secondary: ($secondary, $secondaryhover),
	info: ($info, $infohover),
	warning: ($warning, $warninghover),
	danger: ($danger, $dangerhover),
	light: ($light, $lighthover),
	medium: ($medium, $mediumhover),
	dark: ($dark, $darkhover)
);

.border {
	@each $name, $colors in $cathegory {
		$border-color: nth($colors, 1);

		&-#{$name} {
			border-color: $border-color !important;
		}
	}
}

.bg {
	@each $name, $colors in $cathegory {
		$background-color: nth($colors, 1);
		$background-hover-color: nth($colors, 2);

		&-#{$name} {
			background-color: $background-color !important;

			@at-root a#{&} { // --> a.bg-***
				&:hover {
					background-color: $background-hover-color !important;
				}
			}
			@at-root .boxlink#{&} { // --> .boxlink.bg-*** settings für topic boxes
				&:hover {
					background-color: $background-hover-color !important;
				}
			}
		}
	}
}
.text {
	@each $name, $colors in $cathegory {
		$text-color: nth($colors, 1);

		&-#{$name} {
			color: $text-color !important;
		}
	}
}
$black5 : rgba(0,0,0,0.05);
$black10 : rgba(0,0,0,0.1);
$black20 : rgba(0,0,0,0.2);
$black30 : rgba(0,0,0,0.3);
$black40 : rgba(0,0,0,0.4);
$black50 : rgba(0,0,0,0.5);
$black60 : rgba(0,0,0,0.6);
$black70 : rgba(0,0,0,0.7);
$black80 : rgba(0,0,0,0.8);
$black90 : rgba(0,0,0,0.9);

$white10 : rgba(255,255,255,0.1);
$white20 : rgba(255,255,255,0.2);
$white30 : rgba(255,255,255,0.3);
$white40 : rgba(255,255,255,0.4);
$white50 : rgba(255,255,255,0.5);
$white60 : rgba(255,255,255,0.6);
$white70 : rgba(255,255,255,0.7);
$white80 : rgba(255,255,255,0.8);
$white90 : rgba(255,255,255,0.9);


.bg-black   { background-color: #000000 !important}
.bg-black10 { background-color: $black10 !important;}
.bg-black20 { background-color: $black20 !important;}
.bg-black30 { background-color: $black30 !important;}
.bg-black40 { background-color: $black40 !important;}
.bg-black50 { background-color: $black50 !important;}
.bg-black60 { background-color: $black60 !important;}
.bg-black70 { background-color: $black70 !important;}
.bg-black80 { background-color: $black80 !important;}
.bg-black90 { background-color: $black90 !important;}

.bg-white10 { background-color: $white10 !important;}
.bg-white20 { background-color: $white20 !important;}
.bg-white30 { background-color: $white30 !important;}
.bg-white40 { background-color: $white40 !important;}
.bg-white50 { background-color: $white50 !important;}
.bg-white60 { background-color: $white60 !important;}
.bg-white70 { background-color: $white70 !important;}
.bg-white80 { background-color: $white80 !important;}
.bg-white90 { background-color: $white90 !important;}


// ----------------------------------------------------
// ---------- SETTINGS: OVERLAY - BACKGROUND ----------
// ----------------------------------------------------

.overlay {
	&.dark {
		background: rgba(45,45,45,0.75);
	}
	&.light {
		background: rgba(200,200,200,0.75);
	}
}

// ----------------------------------------------------
// ----------------- SETTINGS: OPACITY -----------------
// ----------------------------------------------------

$opacity-map: (
	0: 0,
	10: 0.1,
	20: 0.2,
	25: 0.25,
	30: 0.3,
	40: 0.4,
	50: 0.5,
	60: 0.6,
	70: 0.7,
	75: 0.75,
	80: 0.8,
	90: 0.9,
	100: 1
);

@each $name, $value in $opacity-map {
	.opacity#{$name} {
		opacity: $value;
	}
}

// ----------------------------------------------------
// ------------ SETTINGS: MARGIN / PADDING ------------
// ----------------------------------------------------
$space-map1: (
	10 : 10px,
	20 : 20px,
	30 : 30px,
	40 : 40px,
	50 : 50px,
	60 : 60px,
	70 : 70px,
	80 : 80px,
	90 : 90px,
	100: 100px,
	120: 120px,
	140: 140px,
	160: 160px,
	180: 180px,
	200: 200px
);

@each $name, $value in $space-map1 {
	.ptop#{$name} {
		padding-top   : $value !important;
	}
	.pbot#{$name} {
		padding-bottom: $value !important;
	}
	.pall#{$name} {
		padding       : $value !important;
	}
}
@each $name, $value in $space-map1 {
	.mtop#{$name} {
		margin-top: $value !important;
	}
	.mbot#{$name} {
		margin-bottom: $value !important;
	}
}

@each $name, $value in $space-map1 {
	.space#{$name} {
		padding-top: $value;
		padding-bottom: $value;
	}
	@media (max-width: 1199px) {
		.space#{$name} {
			padding-top: $value * 0.8;
			padding-bottom: $value  * 0.8;
		}
	}
	@media (max-width: 991px) {
		.space#{$name} {
			padding-top: $value * 0.8;
			padding-bottom: $value  * 0.8;
		}
	}
	@media (max-width: 767px) {
		.space#{$name} {
			padding-top: $value * 0.7;
			padding-bottom: $value  * 0.7;
		}
	}
	@media (max-width: 575px) {
		.space#{$name} {
			padding-top: $value * 0.6;
			padding-bottom: $value  * 0.6;
		}
	}
}

.nospace {
    padding-right: 0px !important;
    padding-left: 0px !important;
}


$spacer: 1rem;
$spacers: (
	/*
  0: 0,
  1: $spacer / 4,
  2: $spacer / 2,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  */
  6: $spacer * 5,
  7: $spacer * 7.5,
  8: $spacer * 10,
);

@each $name, $value in $spacers {
	.p-#{$name} {
		padding       : $value !important;
	}
	.px-#{$name} {
		padding-left  : $value !important;
		padding-right : $value !important;
	}
	.py-#{$name} {
		padding-top   : $value !important;
		padding-bottom: $value !important;
	}
	.pt-#{$name} {
		padding-top   : $value !important;
	}
	.pb-#{$name} {
		padding-bottom: $value !important;
	}
	.pl-#{$name} {
		padding-left  : $value !important;
	}
	.pr-#{$name} {
		padding-right : $value !important;
	}


	@media (min-width: 576px) {
		.p-sm-#{$name} {
			padding       : $value !important;
		}
		.px-sm-#{$name} {
			padding-left  : $value !important;
			padding-right : $value !important;
		}
		.py-sm-#{$name} {
			padding-top   : $value !important;
			padding-bottom: $value !important;
		}
		.pt-sm-#{$name} {
			padding-top   : $value !important;
		}
		.pb-sm-#{$name} {
			padding-bottom: $value !important;
		}
		.pl-sm-#{$name} {
			padding-left  : $value !important;
		}
		.pr-sm-#{$name} {
			padding-right : $value !important;
		}
	}
	@media (min-width: 768px) {
		.p-md-#{$name} {
			padding       : $value !important;
		}
		.px-md-#{$name} {
			padding-left  : $value !important;
			padding-right : $value !important;
		}
		.py-md-#{$name} {
			padding-top   : $value !important;
			padding-bottom: $value !important;
		}
		.pt-md-#{$name} {
			padding-top   : $value !important;
		}
		.pb-md-#{$name} {
			padding-bottom: $value !important;
		}
		.pl-md-#{$name} {
			padding-left  : $value !important;
		}
		.pr-md-#{$name} {
			padding-right : $value !important;
		}
	}
	@media (min-width: 992px) {
		.p-lg-#{$name} {
			padding       : $value !important;
		}
		.px-lg-#{$name} {
			padding-left  : $value !important;
			padding-right : $value !important;
		}
		.py-lg-#{$name} {
			padding-top   : $value !important;
			padding-bottom: $value !important;
		}
		.pt-lg-#{$name} {
			padding-top   : $value !important;
		}
		.pb-lg-#{$name} {
			padding-bottom: $value !important;
		}
		.pl-lg-#{$name} {
			padding-left  : $value !important;
		}
		.pr-lg-#{$name} {
			padding-right : $value !important;
		}
	}
	@media (min-width: 1200px) {
		.p-xl-#{$name} {
			padding       : $value !important;
		}
		.px-xl-#{$name} {
			padding-left  : $value !important;
			padding-right : $value !important;
		}
		.py-xl-#{$name} {
			padding-top   : $value !important;
			padding-bottom: $value !important;
		}
		.pt-xl-#{$name} {
			padding-top   : $value !important;
		}
		.pb-xl-#{$name} {
			padding-bottom: $value !important;
		}
		.pl-xl-#{$name} {
			padding-left  : $value !important;
		}
		.pr-xl-#{$name} {
			padding-right : $value !important;
		}
	}
	@media (min-width: 1400px) {
		.p-xxl-#{$name} {
			padding       : $value !important;
		}
		.px-xxl-#{$name} {
			padding-left  : $value !important;
			padding-right : $value !important;
		}
		.py-xxl-#{$name} {
			padding-top   : $value !important;
			padding-bottom: $value !important;
		}
		.pt-xxl-#{$name} {
			padding-top   : $value !important;
		}
		.pb-xxl-#{$name} {
			padding-bottom: $value !important;
		}
		.pl-xxl-#{$name} {
			padding-left  : $value !important;
		}
		.pr-xxl-#{$name} {
			padding-right : $value !important;
		}
	}

	// margin m6 - m8
	.m-#{$name} {
		margin       : $value !important;
	}
	.mx-#{$name} {
		margin-left  : $value !important;
		margin-right : $value !important;
	}
	.my-#{$name} {
		margin-top   : $value !important;
		margin-bottom: $value !important;
	}
	.mt-#{$name} {
		margin-top   : $value !important;
	}
	.mb-#{$name} {
		margin-bottom: $value !important;
	}
	.ml-#{$name} {
		margin-left  : $value !important;
	}
	.mr-#{$name} {
		margin-right : $value !important;
	}


	@media (min-width: 576px) {
		.m-sm-#{$name} {
			margin       : $value !important;
		}
		.mx-sm-#{$name} {
			margin-left  : $value !important;
			margin-right : $value !important;
		}
		.my-sm-#{$name} {
			margin-top   : $value !important;
			margin-bottom: $value !important;
		}
		.mt-sm-#{$name} {
			margin-top   : $value !important;
		}
		.mb-sm-#{$name} {
			margin-bottom: $value !important;
		}
		.ml-sm-#{$name} {
			margin-left  : $value !important;
		}
		.mr-sm-#{$name} {
			margin-right : $value !important;
		}
	}
	@media (min-width: 768px) {
		.m-md-#{$name} {
			margin       : $value !important;
		}
		.mx-md-#{$name} {
			margin-left  : $value !important;
			margin-right : $value !important;
		}
		.my-md-#{$name} {
			margin-top   : $value !important;
			margin-bottom: $value !important;
		}
		.mt-md-#{$name} {
			margin-top   : $value !important;
		}
		.mb-md-#{$name} {
			margin-bottom: $value !important;
		}
		.ml-md-#{$name} {
			margin-left  : $value !important;
		}
		.mr-md-#{$name} {
			margin-right : $value !important;
		}
	}
	@media (min-width: 992px) {
		.m-lg-#{$name} {
			margin       : $value !important;
		}
		.mx-lg-#{$name} {
			margin-left  : $value !important;
			margin-right : $value !important;
		}
		.my-lg-#{$name} {
			margin-top   : $value !important;
			margin-bottom: $value !important;
		}
		.mt-lg-#{$name} {
			margin-top   : $value !important;
		}
		.mb-lg-#{$name} {
			margin-bottom: $value !important;
		}
		.ml-lg-#{$name} {
			margin-left  : $value !important;
		}
		.mr-lg-#{$name} {
			margin-right : $value !important;
		}
	}
	@media (min-width: 1200px) {
		.m-xl-#{$name} {
			margin       : $value !important;
		}
		.mx-xl-#{$name} {
			margin-left  : $value !important;
			margin-right : $value !important;
		}
		.my-xl-#{$name} {
			margin-top   : $value !important;
			margin-bottom: $value !important;
		}
		.mt-xl-#{$name} {
			margin-top   : $value !important;
		}
		.mb-xl-#{$name} {
			margin-bottom: $value !important;
		}
		.ml-xl-#{$name} {
			margin-left  : $value !important;
		}
		.mr-xl-#{$name} {
			margin-right : $value !important;
		}
	}
	@media (min-width: 1400px) {
		.m-xxl-#{$name} {
			margin       : $value !important;
		}
		.mx-xxl-#{$name} {
			margin-left  : $value !important;
			margin-right : $value !important;
		}
		.my-xxl-#{$name} {
			margin-top   : $value !important;
			margin-bottom: $value !important;
		}
		.mt-xxl-#{$name} {
			margin-top   : $value !important;
		}
		.mb-xxl-#{$name} {
			margin-bottom: $value !important;
		}
		.ml-xxl-#{$name} {
			margin-left  : $value !important;
		}
		.mr-xxl-#{$name} {
			margin-right : $value !important;
		}
	}
}




// custom settings
.column-space {
	padding: 3rem 1.5rem;

	@media (min-width: 768px) {
	}
	@media (min-width: 992px) {
		padding: 4rem 3rem;
	}
	@media (min-width: 1200px) {
		padding: 5rem 3rem;
	}
	@media (min-width: 1400px) {
		padding: 5rem 7.5rem;
	}
}


// ----------------------------------------------------
// ------------------- SCSS-imports -------------------
// ----------------------------------------------------
@import "settings_colors";
@import "settings_spaces";
@import "settings_widths_heights";
@import "settings_container";
@import "effects";


// ----------------------------------------------------
// ---------------- local font-import -----------------
// ----------------------------------------------------

@import "fonts/cinzel_decorative";
@import "fonts/lato";
//@import "fonts/merriweather";
//@import "fonts/merriweather_sans";
//@import "fonts/montserrat";
//@import "fonts/open_sans";
//@import "fonts/open_sans_condensed";
//@import "fonts/raleway";
//@import "fonts/quattrocento";
//@import "fonts/roboto";
//@import "fonts/roboto_condensed";
//@import "fonts/roboto_slab";



// ----------------------------------------------------
// -------------- SETTINGS: DISPLAY TEXT --------------
// ----------------------------------------------------
.display-5 {
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1.2;
}
.display-6 {
    font-size: 1.9rem;
    font-weight: 300;
    line-height: 1.2;
}

// ----------------------------------------------------
// ----------------- EDIT CONTAINER -------------------
// ----------------------------------------------------
.rsfh-lightbox-close,
.rsfh-lightbox-cancel {
    background: $color-warning !important;
    color: #fff !important;
}

// ----------------------------------------------------
// ---------- SETTINGS: ARTICLE BACKGROUNDS -----------
// ----------------------------------------------------

.article-bg-img-1 {
    background-image: url("../img/bg_01.jpg");
    background-attachment: fixed;
    @extend .space80;
}

.article-bg-img-2 {
    background-image: url("../img/pattern_noise_light.jpg");
    background-position: center center;
    background-attachment: fixed;
    //background-size: cover;
    @extend .space80;
}

.article-bg-img-3 {
    background-image: url("../img/pattern_noise_dark.jpg");
    background-attachment: fixed;
    @extend .space80;
}


.article-bg-color-1 {
    background: $color1;
    @extend .space80;
}

.article-bg-color-2 {
    background: $color2;
    @extend .space80;
}

.article-bg-color-3 {
    background: $color3;
    @extend .space80;
}

.article-bg-color-4 {
    background: $color4;
    @extend .space80;
}

.article-bg-gray-4 {
    background: $lightgray4;
    @extend .space80;
}

.article-bg-gray-1 {
    background: $gray3;
    @extend .space80;
}


// ----------------------------------------------------
// ---------- SETTINGS: 100% WITH ARTICLE  ------------
// ----------------------------------------------------

.fullwidth {
    .container-fluid {
        // settings for no space on the edge and no horizontal scrolling
        padding-right: 0px;
        padding-left: 0px;
        overflow: hidden;
    }
}


// ----------------------------------------------------
// -------------- PAGE DEFAULT SETTINGS ---------------
// ----------------------------------------------------

html {
  	font-size: 18px;
    scroll-behavior: smooth;

}

body {
	font-family : 'Lato', sans-serif;
	font-weight : 400;
	color       : $gray6;
	background  : #fff;
	line-height : 160%;
    animation: fadein 2s;
}

strong, b {
	font-weight: 600;
}

// ----------------------------------------------------
// -------------- LINK DEFAULT SETTINGS ---------------
// ----------------------------------------------------

a:link,
a:visited{
	color: $color1;
}

a:hover {
	text-decoration: none;
}

a, img, figure {
    transition: all 0.4s ease-in-out;
}



// ----------------------------------------------------
// -------------------- HEADLINES ---------------------
// ----------------------------------------------------
h1,
.h1 {
	font-family: 'Cinzel Decorative', sans-serif;
	font-size: 2.5rem;
	font-weight: 700;
	line-height: 1.2;
	color: $gray4;
	position: relative;
	letter-spacing: -1px;

}

h2,
.h2 {
	font-family: 'Cinzel Decorative', sans-serif;
	font-size: 2.2rem;
	line-height: 1.2;
	font-weight: 600;
	color: $gray4;
	letter-spacing: -1px;
}

h3,
.h3 {
    font-family: 'Cinzel Decorative', sans-serif;
    font-size: 1.8rem;
	line-height: 1.2;
    font-weight: 500;
    color: $gray8;
	letter-spacing: -1px;
}

h4 {
    font-family: 'Lato', sans-serif;
    font-size: 1.3rem;
    font-weight: 500;
    color: $gray8;
	span {
		font-size: 1.05rem;
	}
}

h5 {
    font-family: 'Lato', sans-serif;
    font-size: 1.1rem;
    font-weight: 500;
    color: $gray8;
}

h6 {
	font-family: 'Lato', sans-serif;
	color: $color3;
}

.subheadline {
	display: block;
	font-size: 70%;
	margin-bottom: 15px;
	text-transform: none;
    font-family: 'Lato', sans-serif;
    letter-spacing: 0;
    font-weight: 400;
    color: $gray8;
}

.text-white {
	h1, h2, h3, h4, h5, h5, .subheadline {
		color: #fff !important;
	}
}

@media (max-width: 991px) {
	h1 { font-size: 2.2rem; }
	h2 { font-size: 2.0rem; }
	h3 { font-size: 1.7rem; }
	h4 { font-size: 1.5rem; }
	h5 { font-size: 1.3rem; }
}


@media (max-width: 575px) {
	h1 { font-size: 2.0rem; }
	h2 { font-size: 1.8rem; }
	h3 { font-size: 1.6rem; }
	h4 { font-size: 1.4rem; }
	h5 { font-size: 1.2rem; }
}

// ----------------------------------------------------
// ------------------ BODY CLASSES --------------------
// ----------------------------------------------------

.no-banner {
    #main {
        padding-top: 135px; //$height;


        @media (min-width: 768px) {
            padding-top: $height-md;
        }

        @media (min-width: 992px) {
            padding-top: $height-lg;
        }
		@media (min-width: 1200px) {
            padding-top: 135px; //$height-xl;
        }
    }
}

// ----------------------------------------------------
// --------------------- SIDEBAR ----------------------
// ----------------------------------------------------
.sidebar {

    // Extra-Formatierung sobald Modul Newslist, Eventlist, Search, Subscribe in der Sidebar platziert ist
    @media(min-width: 992px) {

        &.right {
            .mod_eventlist,
            .mod_newslist,
            .mod_search,
            .mod_subscribe,
			.ce_gallery,
			.ce_text,
			.addresslist {
                // padding: 20px;

                h3 {
                    border-bottom: 1px solid #cccccc;
                    margin: 0px 0px 15px;
                }
            }
        }
    }

}

// ----------------------------------------------------
// --------------- SETTINGS: TEXT-COLOR ---------------
// ----------------------------------------------------
// für Texte auf dunklem Hintergrund

.light-text {
    h1, h2, h3, h4, h5, h6, li, a, a:link {
        color: #fff !important;
        &:after {
            background: #fff !important;
            color: #fff !important;
        }
    }
	.fa, li:before {
		color: #fff !important;
	}
    p, span, figcaption {
        color: $white90 !important;
    }
}

// ----------------------------------------------------
// ---------- SETTINGS: HOVER-LAYER-OVERLAY -----------
// ----------------------------------------------------
	a {
		&.cboxElement,
		&.hyperlink_img {
			position: relative;
			display: inline-block;

			&:after {
				transition: all 0.4s ease-in-out;
				opacity: 0;
				position: absolute;
				z-index: +1;
				top: 0px;
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				-ms-flex-item-align: center;
				align-self: center;
				-ms-flex-pack: center;
				justify-content: center;
				background: transparentize($color1, 0.4);

				content: "\f055";
				font-family: 'FontAwesome';

				font-size: 5rem;
				color: #fff;

			}
			&:hover {
				&::after {

					opacity: 1;
					font-size: 2rem;
				}
			}
		}

		&.hyperlink_img {
			&:after {
				content: "\f0a9";
			}
		}

		&.hyperlink_img[target="_blank"] {
			&:after {
				content: "\f08e";
			}
		}
	}

// ----------------------------------------------------
// -------------- SETTINGS: TEXT_FORMATS --------------
// ----------------------------------------------------

.alert {
    padding: 0 0 0 60px;
    display: block;
    position: relative;
}
.alert-before {
    position: absolute;
    font-family: FontAwesome;
    font-size: 50px;
    left: 0px;
    top: 15px;
}

span.information {
    color: $color-information;
    @extend .alert;
    &:before {
        @extend .alert-before;
        content: "\f05a";
        color: darken($color-information,10);
    }
}
span.confirmation {
    color: $color-confirmation;
    @extend .alert;
    &:before {
        @extend .alert-before;
        content: "\f058";
        color: darken($color-confirmation,10);
    }

}
span.warning {
    color: $color-warning;
    @extend .alert;
    &:before {
        @extend .alert-before;
        content: "\f071";
        color: darken($color-warning,10);
    }
}

blockquote {
    @extend .alert;
    color: $color-blockquote;
    font-style: italic;
    font-size: 16px;
    border-left: none;

    &:before {
        @extend .alert-before;
        content: "\f138";
        color: darken($color-blockquote,10);
        border-left: none;
        font-style: normal;
    }
    em {
        font-style: normal;
        color: darken($color-blockquote,10) !important;
        padding-top: 5px;
    }
}






// ----------------------------------------------------
// ------------------ SETTINGS: LISTS -----------------
// ----------------------------------------------------


// --------------------  list settings -------------------

    ul {
        list-style-type: none;
        padding: 0;
    }

	// extend in li-element
	.list_item {
		padding: 0 0 0 22px;
		margin: 0px 0px 5px 5px;
		position: relative;
		transition: all 0.2s ease-in-out;
	}

	// extend in li:before
	.list_item_before {
		font-family: 'FontAwesome';
		font-size: 1rem;
		color: $gray4;
		position: absolute;
		top: 0px;
		left: 0px;
		margin-right: 8px;
		font-style: normal;

		.text-white & {
			color: #fff
		}
	}



// -------------------- ICON-LISTS --------------------

// Definition Icons für Listen
.icon-mov { background-image: url(../icons/file_icons_1/mov.svg); }
.icon-mpg { background-image: url(../icons/file_icons_1/mpg.svg); }
.icon-mp3 { background-image: url(../icons/file_icons_1/mp3.svg); }
.icon-xls { background-image: url(../icons/file_icons_1/xls.svg); }
.icon-doc { background-image: url(../icons/file_icons_1/doc.svg); }
.icon-pdf { background-image: url(../icons/file_icons_1/pdf.svg); }
.icon-rar { background-image: url(../icons/file_icons_1/zip.svg); }
.icon-zip { background-image: url(../icons/file_icons_1/zip.svg); }
.icon-download 		{};
.icon-link-extern 	{};
.icon-link-intern 	{};
.icon-email 		{};
.icon-phone 		{};
.icon-mobile 		{};


// Definition Font-Symbole für Listen
.symb-standard 		{ content: '\f105';}
.symb-download 		{ content: '\f019' !important;}
.symb-link-intern 	{ content: '\f0a9' !important;}
.symb-link-extern 	{ content: '\f08e' !important;}
.symb-checked 		{ content: '\f00c' !important;}
.symb-arrow 		{ content: '\f061' !important;}

.symb-mov			{ content: '\f1c8' !important;}
.symb-mpg			{ content: '\f1c8' !important;}
.symb-mp4			{ content: '\f1c8' !important;}
.symb-mp3			{ content: '\f1c7' !important;}
.symb-pdf			{ content: '\f1c1' !important;}
.symb-zip			{ content: '\f1c6' !important;}
.symb-rar			{ content: '\f1c6' !important;}
.symb-doc			{ content: '\f1c2' !important;}
.symb-xls			{ content: '\f1c3' !important;}


// ul-Klassen
ul {
	// Icon - Listen
	&.list-img {
		li {
			background-repeat: no-repeat;
			background-size: 2rem;
			line-height: 2rem;
			padding-left: 2.5rem;
			margin-bottom: 0.5rem;

			&.ext-mov { @extend .icon-mov; }
			&.ext-mpg { @extend .icon-mpg; }
			&.ext-mp3 { @extend .icon-mp3; }
			&.ext-xls { @extend .icon-xls; }
			&.ext-doc { @extend .icon-doc; }
			&.ext-pdf { @extend .icon-pdf; }
			&.ext-rar { @extend .icon-rar; }
			&.ext-zip { @extend .icon-zip; }
		}
	}

	// Font-Symbol - Listen
	&.list-font {
		li {
			line-height: 2rem;
			padding-left: 2.5rem;
			margin-bottom: 0.5rem;
			position: relative;
			&:before {
				position: absolute;
				left: 0px;
				font-size: 2rem;
				line-height: 2rem;
				font-family: 'FontAwesome';
			}

			&.ext-mov {&:before {@extend .symb-mov;}}
			&.ext-mpg {&:before {@extend .symb-mpg;}}
			&.ext-mp4 {&:before {@extend .symb-mp4;}}
			&.ext-mp3 {&:before {@extend .symb-mp3;}}
			&.ext-xls {&:before {@extend .symb-xls;}}
			&.ext-doc {&:before {@extend .symb-doc;}}
			&.ext-pdf {&:before {@extend .symb-pdf;}}
			&.ext-rar {&:before {@extend .symb-rar;}}
			&.ext-zip {&:before {@extend .symb-zip;}}

		}
	}

	&.list-checked {
		li {
			&:before {
				@extend .symb-checked;
			}
		}
	}
	&.list-arrow {
		li {
			&:before {
				@extend .symb-arrow;
			}
		}
	}
}

// Standard-Liste im Texteditor
.text,
.text-block,
.ce_text {

	ul {
		li {
			@extend .list_item;

			&:before {
				@extend .list_item_before;
				@extend .symb-standard;
				color: $color1;
			}
		}
	}
}




// ----------------------------------------------------
// ----------------- SETTINGS: IMAGES -----------------
// ----------------------------------------------------

figure {
	margin: 0;

	&.image_container {
		//width: 100%;

		figcaption {
			font-style: italic;
			font-size: 0.8rem;
			padding: 5px 0px 12px 10px;
			line-height: 1.2rem;
			color: $gray9;

			.text-white & {
				color: #fff
			}
		}


        &.float_above,
		&.float_below {
            margin: 0px 0px 20px 0px !important;
            img {
                width: 100%;
            }
        }
        &.float_left,
        &.float_right {
            margin-bottom: 8px;
            img {
                width: 100%;
                height: auto;

            }
        }

		@media (min-width: 768px) {
			&.float_left {
				min-width: 250px;
                max-width: 300px;
				margin-right: 30px;
				margin-bottom: 8px;
			}
			&.float_right {
				min-width: 250px;
                max-width: 300px;
				margin-left: 30px;
				margin-bottom: 8px;
			}
		}


        img {
            width: 100%;
			height: auto;
        }


	}

    &.round-image {
        img {
            border-radius: 50%;
        }

    }
}

// ----------------------------------------------------
// ------------ SETTINGS: BACKGROUNDIMAGES ------------
// ----------------------------------------------------

.bg-default {
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}

.bg-repeat {
	background-repeat: repeat;
}

.bg-fixed {
	background-attachment: fixed;
	background-size: cover;
}

.bg-left 	{ background-position-x: left;}
.bg-right 	{ background-position-x: right;}
.bg-center 	{ background-position-x: center;}

.bg-top 	{ background-position-y: top;}
.bg-bottom 	{ background-position-y: bottom;}
.bg-middle 	{ background-position-y: center;}



// ----------------------------------------------------
// ------------------ ICON SETTINGS -------------------
// ----------------------------------------------------

$iconmap: (
	bigger: (150px, 120px, 80px, 40px),
	big: (120px, 100px, 80px, 40px),
	medium: (80px, 80px, 60px, 40px),
	small: (60px, 60px, 60px, 40px),
	extrasmall: (40px, 40px, 40px, 40px),
);

.icon {
	span {
		transition: all ease-in-out 0.2s;

		&:before {
			font-family: 'FontAwesome';
			content: attr(data-fa-icon);
		}
		&.round-icon {
			border-radius: 50%;
			text-align: center;
		}
		&.square-icon {
			border-radius: 5px;
			text-align: center;
		}
	}

	@each $name, $value in $iconmap {
		$icon-xl: 		nth($value, 1);
		$icon-lg: 		nth($value, 2);
		$icon-md: 		nth($value, 3);
		$icon-sm: 		nth($value, 4);



		&.#{$name} {
			span {
				height: $icon-sm/1.5;
				width: $icon-sm/1.5;

				&:before {
					font-size: $icon-sm/1.5;
					line-height: $icon-sm/1.5;
				}

				&.round-icon,
				&.square-icon {
					height: $icon-sm;
					width: $icon-sm;

					&:before {
						line-height: $icon-sm;
					}
				}
			}
		}



		@media (min-width: 768px) {
			&.#{$name} {
				span {
					height: $icon-md/1.5;
					width: $icon-md/1.5;

					&:before {
						font-size: $icon-md/1.5;
						line-height: $icon-md/1.5;
					}

					&.round-icon,
					&.square-icon {
						height: $icon-md;
						width: $icon-md;

						&:before {
							line-height: $icon-md;
						}
					}
				}
			}
		}


		@media (min-width: 992px) {
			&.#{$name} {
				span {
					height: $icon-lg/1.5;
					width: $icon-lg/1.5;

					&:before {
						font-size: $icon-lg/1.5;
						line-height: $icon-lg/1.5;
					}

					&.round-icon,
					&.square-icon {
						height: $icon-lg;
						width: $icon-lg;

						&:before {
							line-height: $icon-lg;
						}
					}
				}
			}
		}

		@media (min-width: 1200px) {
			&.#{$name} {
				span {
					height: $icon-xl/1.5;
					width: $icon-xl/1.5;

					&:before {
						font-size: $icon-xl/1.5;
						line-height: $icon-xl/1.5;
					}

					&.round-icon,
					&.square-icon {
						height: $icon-xl;
						width: $icon-xl;

						&:before {
							line-height: $icon-xl;
						}
					}
				}
			}
		}
	}
}


// ----------------------------------------------------
// ----------- BOOTSTRAP BUTTONS CUSTOMIZE ------------
// ----------------------------------------------------

$buttons: (
    primary: 	($primary, 	 darken($primary,10%), 		#fff, 	#fff),
    secondary: 	($secondary, darken($secondary,10%), 	#fff, 	#fff),
    info: 		($info, 	 darken($info,10%), 		#fff, 	#fff),
    warning: 	($warning, 	 darken($warning,10%), 		#fff, 	#fff),
    danger: 	($danger, 	 darken($danger,10%), 		#fff, 	#fff),
    success: 	($success, 	 success($success,10%), 	#fff, 	#fff),
    light: 		($light, 	 darken($light,10%), 		$gray4, $gray4),
    dark: 		($dark, 	 darken($dark,10%), 		#fff, 	#fff)
);

.btn {
	margin-top: 10px;
	border-radius: 0px;
	text-transform: uppercase;
	transition: all ease 0.4s;

    &:before,
    &:after {
        font-family: FontAwesome;
    }

    &-lg {
        padding: .5rem 1.25rem;
    }

    &-xl {
        font-size: 1.5rem;
        padding: .75rem 1.5rem;
    }

	@extend .effect-grow;

	@each $name, $colors in $buttons {
		$bgcolor: 			nth($colors, 1);
		$bgcolorhover: 		nth($colors, 2);
		$fontcolor: 		nth($colors, 3);
		$fontcolorhover: 	nth($colors, 4);

		&-#{$name} {
			background-color: $bgcolor;
			border-color: $bgcolor;

			&:link {
				color: $fontcolor;
			}
			&:visited {
				color: $fontcolor;
			}
			&:hover,
            &:active,
            &:focus {
				background-color: $bgcolorhover !important;
				border-color: transparent !important;
			}
			&:focus, &:active {
				box-shadow: none;
			}

			&.btn-round-icon {
				&:before,
				&:after {
					color: $bgcolor;
					background-color: $fontcolor;
				}
			}
		}

		&-outline-#{$name} {
			background-color: transparent;
			border-color: $bgcolor;

			&:link {
				color: $bgcolor;
			}
			&:visited {
				color: $bgcolor;
			}
			&:hover {
				color: $fontcolorhover;
				background-color: $bgcolorhover;
				border-color: transparent;
			}
			&:focus, &:active {
				box-shadow: none;
			}
		}
	}
}

.btn-link {
	text-decoration: none !important;

	&.primary:after 	{ @extend .text-primary;}
	&.secondary:after 	{ @extend .text-secondary;}
	&.info:after 		{ @extend .text-info;}
	&.dark:after 		{ @extend .text-dark;}
	&.light:after 		{ @extend .text-light;}

	&.primary:before 	{ @extend .text-primary;}
	&.secondary:before 	{ @extend .text-secondary;}
	&.info:before 		{ @extend .text-info;}
	&.dark:before 		{ @extend .text-dark;}
	&.light:before 		{ @extend .text-light;}
}


.btn-next {
	&:before {
		content: "\f0a9";
		margin-right: 8px;
	}
}

.btn-prev {
	&:before {
		content: "\f0a8";
		margin-right: 8px;
	}
}

.btn-link-extern {
	&:before {
		content: "\f08e";
		margin-right: 8px;
	}

}

.btn-link-intern {
	&:before {
		content: "\f061";
		margin-right: 8px;
	}
}

.btn-download {
	&:before {
		content: "\f019";
		margin-right: 8px;
	}
}

.btn-round-icon {
	// kann danach global "extended" werden
	&:before,
	&:after {
		width: 1.6rem;
		height: 1.6rem;
		line-height: 1.6rem;
		font-size: 90%;
		border-radius: 50%;
		display: inline-block;
	}
	&.btn-lg {
		&:before,
		&:after {
			width: 2rem;
			height: 2rem;
			line-height: 2rem;
		}
	}
	&.btn-sm {
		&:before,
		&:after {
			width: 1.4rem;
			height: 1.4rem;
			line-height: 1.4rem;
		}
	}
}



// ----------------------------------------------------
// --------------------- TEXT-LINK --------------------
// ----------------------------------------------------


.txt-link {
    position: relative;
    padding-left: 30px;
    font-size: 16px;
    font-weight: 500;

    &:link,
    &:visited {
        color: $color1;
    }
    &:hover {
        color: $gray4;
        //padding-left: 35px;
        &:before {
            background: $color1;
            color: #fff;
        }
    }

    &:before {
        content: "\f105";
        background: $color2;
        color: #fff;
        position: absolute;
        top: -2px;
        left: 0px;
        padding: 5px 10px;

        border-radius: 0%;
        width: 24px;
        height: 24px;
        font-family: FontAwesome;
        font-size: 13px;
        line-height: 13px;
        transition: all 0.4s ease-in-out;
    }


	@each $name, $colors in $buttons {
		$bgcolor: 			nth($colors, 1);
		$bgcolorhover: 		nth($colors, 2);
		$fontcolor: 		nth($colors, 3);
		$fontcolorhover: 	nth($colors, 4);

		&-#{$name} {
			&:before {
				background-color: $bgcolor;
				color: $fontcolor;
			}
			&:hover {
				&:before {
					background-color: $bgcolorhover;
					color: $fontcolorhover;
				}
			}
		}
    }
}



// --------------------------------------------------------------------------------------------------------
/* ---                                                                                                  --- */
/* ---                                              HEADER                                              --- */
/* ---                                                                                                  --- */
// --------------------------------------------------------------------------------------------------------



#header {
	.header-navi {
        z-index: 2000;
		background: $black40;

		.ce_text {
			margin: 0px;
		}



		// ----------------------------------------------------
		// ---------------------- Topbar ----------------------
		// ----------------------------------------------------

		.topbar-wrapper {
			background: transparentize($color3, 0.9);
		}

		.topbar {
			padding: 10px 0 15px;
			color: $lightgray4;

			a,
			a:link,
			a:visited,
			a:focus {
				color: $lightgray4;
                padding-left: 5px;
			}

			a:hover {
				color: #fff;
			}

			.contact {
				display: inline-block;
				ul {
					list-style-type: none;
					margin: 0px;

					li {
						display: inline-block;
						margin: 0px 20px 0px 0px;
						padding: 5px 10px;
                        background: $white20;
						&:last-child {
							margin-right: 0px;
						}
                        &:before {
                            content: "\f041";
                            color: #fff;
                            position: relative;
                        }
                        @media only screen and (max-width: 991px) {
                            font-size: 0.8rem;
                            margin-right: 5px;
                            &:before {
                                display: none;
                            }
                        }

					}
					&.round-icon {
						li {
							em {
								background: $color2;
								border-radius: 50%;
								color: #fff;
								width: 30px;
								height: 30px;
								line-height: 30px;
								position: relative;

								&:before {
									position: absolute;
									left: 8px;
								}
								&.fa-phone {
									&:before {
										left: 9px;
									}
								}

							}
						}

					}
				}
			}

            // --- Modul Customnav
			.mod_customnav {
                &.metanavi {
                    h1,h2,h3,h4,h5,h6 {
                        display: none;
                    }

                    ul {
                        li {
                            display: inline-block;
                            margin: 10px 0px 10px 15px;
                        }
                    }

                }
            }
		}

		&.style1 {
		}
		&.style2 {
			@media only screen and (max-width: 991px) {
				.topbar {
					margin-right: 60px;
				}
			}
		}
		&.style3 {
			@media only screen and (max-width: 991px) {
				.topbar {
					margin-right: 60px;
					padding-top: 12px;
				}
			}
		}
		&.style4 {
		}


        // ----------------------------------------------------
        // ------------------- NAVI_WRAPPER -------------------
        // ----------------------------------------------------
        // wrapper around logo and navi


		&.style3 {
			@media only screen and (min-width: 992px) {
				.navi-wrapper {
					background: darken($color3,10%);
				}
			}
		}
		&.style4 {
			@media only screen and (min-width: 992px) {
				.navi-wrapper {
					background: darken($color3,10%);
				}
			}
		}


        // ----------------------------------------------------
        // ----------------------- LOGO -----------------------
        // ----------------------------------------------------

        .logo {
            position: fixed;
            z-index: +1;
            top: 0px;
            filter: drop-shadow(0px -1px 5px rgba(50,50,0,0.1));

			.image_container {
                padding: 15px 15px 30px 15px;
                background: #fff;
                clip-path: circle(95% at 50% 0);
                //clip-path: polygon(0 0, 100% 0%, 100% 90%, 50% 100%, 0 90%);

				* {
					transition: none;
				}
                img {
                    width: 150px;
                    transition: all ease-in-out 0.2s;
                }
			}

            &:hover {
                .image_container {
                    padding-top: 30px;
                }
            }

			.image_container {
				* {
					transition: none;
				}
			}

            @media only screen and (max-width: 1199px) {
				.image_container {
				}
			}

            @media only screen and (max-width: 991px) {
                .image_container {
                    img {
						height: 50px;
						width: auto;
					}
                }
            }
			@media only screen and (max-width: 767px) {
                .image_container {

                }
            }
        }


		// ----------------------------------------------------
		// --------------------- Mainnavi ---------------------
		// ----------------------------------------------------

		.mod_navigation {
			&.mainnavi {
				.level_1 {
					> li {
						margin-left: 20px;
                        position: relative;

						@media only screen and (min-width: 992px) {
						> &.home {
							display: none;
							}
						}

						> a, > strong {
							padding: 5px 0px 12px;
							display: block;
							text-decoration: none;
							color: #fff;
							text-transform: uppercase;
							font-size: 1.1rem;
							font-weight: 300;
							text-align: left;
                            position: relative;
                            &:after {
                                content: ' ';
                                position: absolute;
                                left: 0px;
                                bottom: 14px;
                                height: 5px;
                                width: 100%;
                                background: $white30;
                                opacity: 0;
                                transition: all ease-in-out 0.2s;
                            }
						}

						> a:hover,
						> a.trail,
						> strong {
							color: #fff;
							//background: $color2;
                            //border-bottom: 3px solid $white40;
						}


					}
					// if submenu, show icon
					> li.submenu > {
						strong:nth-last-child(2):after,
						a:nth-last-child(2):after {
							//content: "\f107";
							//margin-left: 5px;
							font-family: FontAwesome;
							font-size: 13px;
							line-height: 13px;
						}
					}

					// hover-color, if submenu is opened
					> li {
						&:active,
						&:focus,
						&:hover,
                        &.trail,
                        &.active {
							> a,
							> strong {
								color: #fff;
                                &:after {
                                    bottom: 11px;
                                    background: $white30;
                                    opacity: 1;
                                }
							}
						}
					}
					.level_2_right {
						.level_2 {
							// letzter sichbarer Hauptnavi-Item -> Subnavi rechts ausrichten, um Querscroller zu vermeiden
							right: 0px;
						}
					}

				}


				.level_2, .level_3 {
					width: 250px;
					transition: all 0.4s ease-in-out;
                    text-align: left;
                    left: -20px;

					> li {
						border-bottom: 1px solid $black60;
						width: 250px;

						> a,
						> strong {
							position: relative;
							display: block;
							padding: 10px 20px;
							text-transform: uppercase;
							font-size: 0.9rem;
							font-weight: 300;
							color: #fff;
							background: $black50;

							&:before {
								content: "\f105";
								position: absolute;
								top: 15px;
								left: 20px;
								font-family: FontAwesome;
								font-size: 13px;
								line-height: 13px;
								opacity: 0;
								transition: opacity 0.4s ease-in-out;
							}
						}

						> a:hover,
						> a.trail,
						> strong {
							padding-left: 35px;
							color: #fff;
							background: $black60;

							&:before {
								opacity: 1;
							}
						}
					}
				}

				// ----- FORMAT LEVEL 2 -----
				.level_2 {
					// format Level2 bei li & a active und Navigieren zu L3 : L2 li & a sollen Hover-Zustand behalten
					> li {
						&:active,
						&:focus,
						&:hover {

							> a {
								background:  $black60;
								padding-left: 35px;
								color: #fff;
							}
							> a:before {
								opacity: 1;
							}

						}
					}
				}

				// ----- FORMAT LEVEL 3 -----
				.level_3 {
					position: absolute;
					left: 0px;
					margin: -45px 0 0 250px !important;
				}


				@media only screen and (max-width: 992px) {
					display: none;
				}
			} // end .mainnavi
		} // end mod_navigation


		&.style1 {
			.mod_navigation {
				&.mainnavi {
					top: 25px;
				}
			}


		}
		&.style2 {		}
		&.style1 {		}
		&.style2 {		}



		// header-navi mit fixed-behavior
        // wenn fixed-top-off Class aus dem Modul-Code - dann hat fixed bei scriolling keinen Einfluss auf header-navi

        &.fixed-top-off {
            position: absolute;
            width: 100%;

            @media (min-width: 992px) {
                height: 110px; //$height-lg;
            }

			@media (min-width: 1200px) {
                height: 110px; //$height-xl;
            }

        }

        &.fixed-top-on {
            position: fixed;
            z-index: 1005;
            top: 0;
            width: 100%;
            //background: $white90;

            .topbar {
                display: none !important;
            }
            .logo {
                .image_container {
                    img {
                        height: 30px;
                        width: auto;
                    }
                }
            }

            .mod_navigation.mainnavi {
                top: 0px;

                .level_2 {
                    margin-top: 0px;
                }
            }
        }


		// ----------------------------------------------------
		// ------- MM-Menu - mobile Navigation - Button ------- */
		// ----------------------------------------------------
		.dk_menu {
			position: absolute;
			display: none;
			right: 15px;
			top: 12px; //56px;
			z-index: 2001;


            .dk_mobilmenu {
                display   : block;
                width     : 40px;
                height    : 50px;
                position  : relative;
                margin-top: 3px;
                cursor    : pointer;
                span{
                    width             : inherit;
                    height            : 5px;
                    background-color  : $lightgray3;
                    font-size         : 0;
                    position          : absolute;
                    top: 10px;
                    -webkit-transition: background-color .3 ease;
                    transition        : background-color .3 ease;
                }
                span:before,
                span:after {
                    content           : '';
                    position          : absolute;
                    width             : 100%;
                    height            : 100%;
                    left              : 0;
                    background-color  : $lightgray3;
                    -webkit-transition: all .3s ease;
                    transition        : all .3s ease;
                }
                span:before{
                    -webkit-transform: translateY(-13px) ;
                    transform        : translateY(-13px);
                }
                span:after{
                    -webkit-transform: translateY(13px) ;
                    transform        : translateY(13px) ;
                }

                .mm-wrapper_opened & {
                    span{
                        background-color: transparent;
                        &:before{
                            -webkit-transform: translateY(0) rotate(45deg);
                            transform        : translateY(0) rotate(45deg);
                        }
                        &:after{
                            -webkit-transform: translateY(0) rotate(-45deg);
                            transform        : translateY(0) rotate(-45deg);
                        }
                    }
                }
            }


/*
			.dk_mobilmenu {
				padding: 8px 10px 5px;
				background: $color1;
				display: block;

				.fa {
					color: #fff;
					font-size: 24px;
				}
			}
            */
			@media only screen and (min-width: 992px) {
				&.fixed-top-off,
				&.fixed-top-on {
					display: none !important;
				}
			}


			@media only screen and (max-width: 991px) {
				display: block;

				// dk_menu Button default Position relative, damit button mitscrollt, bei fixer Navi bekommt dk_menu auch eine Klasse für fixes Position

				&.fixed-top-off {
					position: absolute;
				}

				&.fixed-top-on {
					position: fixed;
					top: 7px !important;
				}

			}

			@media only screen and (max-width: 768px) {
				top: 12px;
			}

		}

		&.style1 {
			.dk_menu {
				@media only screen and (min-width: 768px) {
					top: 56px;
				}
			}
		}

	} // end header-navi

    // Artikel unter navi-header mit padding-top-Wert, wenn bei header-navi mit fixed-top gearbeitet wird (position: absolute)
    .mod_article {
		padding-top: 0px;

		@media (min-width: 768px) {
			padding-top: $height-md;
		}

		@media (min-width: 992px) {
			padding-top: $height-lg;
		}
		@media (min-width: 1200px) {
			padding-top: $height-xl;
		}

	}




}

// ----------------------------------------------------
/* ----- MM-Menu - mobile Navigation - OffCanvas ------ */
// ----------------------------------------------------
// outside header


.mm-menu_offcanvas {
    width: 85%;
}



.mm-menu {
    background: darken($color1,20%);
	color: #fff;



	.mm-panel {
		// Menu-Title (incl Submenu)

		.mm-navbar {
			visibility: hidden;
			background: $color1;
			text-align: left;

			a, >* {
				color: #fff;
			}

			// Button (Arrows)
			.mm-btn {
				&:after,
				&:before {
					border-color: $white60;
				}
			}
		}

	}


	.mm-listview {
		> li {
			//border-bottom: 1px solid darken($color1,10%);

			&:not(.mm-divider):after {
				border-bottom: 0px solid darken($color1,10%);
			}

			&:hover {
				background: darken($color1,15%);
			}

			&.trail {
				background: darken($color1,10%);
			}
		}

		.mm-listitem>.mm-listitem__btn,
		.mm-listitem>.mm-panel {
			background: $white10;
		}

		.mm-listitem_opened>.mm-listitem__btn,
		.mm-listitem_opened>.mm-panel {
			background: $white10;
		}

		&.level_1 {
			// Link-item
			a, .active, strong.trail {
				text-transform: uppercase;
				padding: 13px 30px 10px 20px;
			}
			strong.trail {
				font-weight: 400;
				position: relative;
				width: 100%;
				&:after {
					content: "\f107";
					font-family: FontAwesome;
					font-size: 24px;
					color: $white50;
					background: $white10;
					position: absolute;
					top: 0px;
					right: 0px;
					width: 51px;
					height: 44px;

					padding: 14px 18px;

				}
			}
			.active {
				background: darken($color1,0%);
				display: block;
				width: 100%;
			}
		}
		&.level_2 {
			font-size: 0.95rem;
			background: darken($color1,15%);
			a, .active, {
				padding: 13px 30px 10px 30px;
			}
		}

		.mm-listitem_vertical>.mm-panel {
			padding: 0px;
		}
	}

}

// ----------------------------------------------------
// --------------------- SUBNAVI ----------------------
// ----------------------------------------------------
.subnavi {
    margin-bottom: 60px;
    h3 {
        border-bottom: 1px solid #cccccc;
    }

    ul {
        list-style-type: none;
        margin: 0px;

        li {
            border-bottom: 1px solid #ddd;
            transition: all 0.4s ease-in-out;


            a, strong {
                    position: relative;
					padding: 10px 5px;
					display: block;
                    color: $gray4;


					&:hover {
						padding-left: 20px;
                        color: $color1;
					}

					&.trail:hover,
					&.active:hover {
						padding-left: 20px;
					}
				}

            a:before,
            strong:before {
                content: "\f105";
                position: absolute;
                top: 15px;
                left: 5px;
                font-family: FontAwesome;
                font-size: 0.8rem;
                line-height: 13px;
                opacity: 0;
                transition: opacity 0.4s ease-in-out;

            }
            a:hover:before,
            a.trail:before,
            strong.active:before {
                opacity: 1;
            }

            .active, .trail, .forward {
              padding-left: 20px;
              color: $color1;
            }

        }
    }
}




// --------------------------------------------------------------------------------------------------------
// ---                                                                                                  ---
// ---                                              OVERLAY-MENU                                        ---
// ---                                                                                                  ---
// --------------------------------------------------------------------------------------------------------
// outside header - other styles in separate css-file

.overlay-menu {
    background: $gray4;

    .mod_navigation {
        .level_1 {
            > li {
                color: #fff;
            }

        }
    }
}




// --------------------------------------------------------------------------------------------------------
// ---                                                                                                  ---
// ---                                              FOOTER                                              ---
// ---                                                                                                  ---
// --------------------------------------------------------------------------------------------------------

#footer {
	.footercontainer {
		background: $gray2;
		position: relative;
		padding: 50px 0 40px;
        color: desaturate($gray9, 10%);
        font-size: 14px;

	}

    // ----------------------------------------------------
    // -------------- FOOTER DEFAULT FORMAT ---------------
    // ----------------------------------------------------

	h3 {
		color: $lightgray1 !important;
		font-size: 1.5rem;
	}
	h4 {
		color: $gray8 !important;
		font-size: 1.25rem;
	}

	h5 {
		color: $lightgray1;
		font-size: 1rem;
	}

	p {
		margin: 0 0 0px;
        color: $lightgray1;
		line-height: 1.6;
	}

    a,
	a:link,
	a:focus,
	a:visited {
		color: desaturate($gray9, 10%);
	}
	a:hover {
		color: $lightgray4;
	}
	// customize address-card in footer
	.card {
		.card-body {
			.contact {
				li {
					border-bottom: none;
					padding-top: 0px;
					padding-bottom: 0px;
                    line-height: 160%;
                    &.address {
                        margin-bottom: 10px;
                        &:before {
                            content: none;
                        }
                    }
				}
				li:before {
					color: $lightgray1 !important;
                    font-size: 0.75rem;
				}
			}
            li {
                line-height: 160% !important;
            }
		}
    }

    // ----------------------------------------------------
    // -------------- FOOTER Footer Address ---------------
    // ----------------------------------------------------
    .footer-address {
        // class around address-list in ce_text
        margin: auto;
    }



    // ----------------------------------------------------
    // ------------------ FOOTER FORMS --------------------
    // ----------------------------------------------------
    form {
        .submit {
            background: $white10;
            &:hover {
                background: $white30;
            }
        }
        input.text, select, textarea {
            background: $white80;
        }
    }

    // ----------------------------------------------------
    // ---------------- FOOTER CUSTOMNAV ------------------
    // ----------------------------------------------------

    .mod_customnav {
        > span {
            display: block;
			color: $color1 !important;
			font-size: 1.3rem;
            @extend .h3;
            margin-bottom: 0.5rem;
        }

        margin-bottom: 30px;

        ul {
            li {
				@extend .list_item;
				padding: 0 0 0 15px;
                line-height: 160%;
                margin-bottom: 0px;
				&:before {
					@extend .list_item_before;
                    @extend .symb-standard;
					color: $color1;
				}
           }
        }

    }


    // ----------------------------------------------------
    // ---------------- FOOTER SCROLLTOP ------------------
    // ----------------------------------------------------
    .scrolltop {
		right: 30px;
		bottom: 30px;
		margin: auto;
		position: fixed;
		z-index: +1;
		width: 55px;
		height: 55px;
		transition: all .4s;

		&:hover {
			//top: -25px;
		}
        a {
            overflow: hidden;
			cursor: pointer;
			z-index: 99;
            &:after {
                font-family: fontawesome;
                content: "\f106";
                display: block;
                text-indent: 0;
                padding: 10px 2px 5px 9px;
                font-size: 50px;
				height: 54px;
				width: 54px;
				line-height: 35px;
                color: #fff;
                background: $color2;
				transition: all .4s;
				overflow: hidden;
				border: 2px solid #fff;
            }

            &:hover:after {
				color: #fff;
				background: $color1;
				padding: 3px 2px 5px 9px;
            }
        }
    }

    // ----------------------------------------------------
    // -------------------- FOOTERBAR ---------------------
    // ----------------------------------------------------
    // Platzierung von Copyright und Links

    .footerbar {
        background: $gray1;
		.container {
			position: relative;
		}
        .copyright {
            padding: 15px 0px;
            font-size: 13px;
            margin-bottom: 0px;
        }
    }

}
// --------------------------------------------------------------------------------------------------------
// ---                                           ENDE FOOTER                                            ---
// --------------------------------------------------------------------------------------------------------







// ----------------------------------------------------
// ------- Weiterlesen-Link bei News und Events -------
// ----------------------------------------------------

p {
	&.more a,
	&.back a,
	&.info a {

		font-size: 14px;
		position: relative;
        padding-left: 20px;
        transition: all 0.4s ease-in-out;

        &:hover {
            color: $gray4;
            margin-left: 5px;
        }
	}

	&.more a:before,
	&.back a:before,
	&.info a:before {
        font-family: FontAwesome;
		content: "\f138";
        color: $gray4;
        font-size: 16px;
		position: absolute;
		top: -2px;
		left: 0;
	}
    &.back a:before {
        content: "\f137";
    }


	&.info {
        position: relative;
        padding-left: 18px;
        font-size: 12px;
        color: $gray7;
        border-bottom: 1px solid $lightgray4;

        time,
        .date {
            color: $color1;

            &:before {
                font-family: FontAwesome;
                content: "\f073";
                position: absolute;
                color: $color1;
                top: 0px;
                left: 0;
            }
        }
        // start-/endtime in events
        .time,
        .location {
            padding-left: 15px;
            color: $color1;
            &:before {
                font-family: FontAwesome;
                content: "\f017";
                color: $color1;

            }
        }

        .location:before {
            content: "\f041";
        }


	}
}


// ----------------------------------------------------
// -------------------- Pagination --------------------
// ----------------------------------------------------

.pagination {
	display: block;
	float: none;
	clear: both;
	margin: 10px 5px;
	padding-left: 0;
	ul {
		list-style-type: none;
		float: right;
		margin: 0;
		padding: 0;
		display: inline-flex;
	}
	li {
		strong,a {
            display: inline-block;
            float: left;
            padding: 5px 12px;
            margin: 0 1px;
            color: $gray6;
            font-size: 0.9rem;
            background: $lightgray3;
            transition: all 0.4s ease-in-out;
			&:hover {
				background: $color1;
				color: #fff;
			}
		}
		strong {
			background: $color1;
			color: #fff;
		}

	}
	> p {
		float: left;
		font-size: 0.9rem;

        &:before {
            font-family: FontAwesome;
            content: "\f016";
            color: $color1;
            margin-right: 5px;
        }
	}
}






// --------------------------------------------------------------------------------------------------------
// ---                                                                                                  ---
// ---                                              MODULE                                              ---
// ---                                                                                                  ---
// --------------------------------------------------------------------------------------------------------


// ----------------------------------------------------
// ---------------- MODULE - Kalender ----------------
// ----------------------------------------------------
.mod_calendar {
    margin-bottom: 40px;

    .calendar {
        border: 1px solid $lightgray1;
        background: transparentize($lightgray4,0.5);

        thead tr {
            border-bottom: 1px solid $black10;
        }

        .head {
            &.current {
                text-align: center;
            }
			&.previous a {
				padding: 5px 15px;
				font-size: 16px;
				float: left;
                background: $lightgray3;
			}
			&.next a {
				padding: 5px 15px;
				font-size: 16px;
				float: right;
                background: $lightgray3;
			}
		}



        .label {
            display: table-cell;
            color: $gray6;
            background: $lightgray2;
            border-radius: 0px;
            padding: 10px;
        }
        td {
			font-weight: 400;
			padding: 10px;
			text-align: center;
            border: 1px solid $lightgray3;

			&.days {
                &.active {
                    position: relative;
                    background: $lightgray2;
                    font-weight: 600;

                    .event {
                        display: none;
                    }
                    .header {
                        color: $color1;
                        cursor: pointer;

                    }
                    &:hover .event {
                        background: none repeat scroll 0 0 $gray6;
                        color: #fff;
                        display: block;
                        height: auto;
                        margin: 10px 0;
                        padding: 8px;
                        position: absolute;
                        top: 0;
                        width: 220px;
                        z-index: 99;
                    }
                }
                &.weekend {
                    font-weight: 600;
                }
            }

			.event a {
				color: #fff;
				font-size: 12px;
			}
		}
    }


}


// ----------------------------------------------------
// ---------------- MODULE FAQ - LISTE ----------------
// ----------------------------------------------------

.mod_faqlist {
    margin-bottom: 60px;

    h2 {
        display: none;
    }
    ul {
		padding-left: 0;
        li {
            padding-left: 5px;
            &:before {
                font-family: FontAwesome;
                font-size: 18px;
                content: "\f059";margin-right: 10px;
            }
        }
    }
}


// ----------------------------------------------------
// ----------------- MODULE FAQ - LESER ---------------
// ----------------------------------------------------
.mod_faqreader,
.mod_faqpage {
    p.info {
        padding-left: 0px;
    }
    p.info:before {
        font: normal normal normal 14px/1 FontAwesome;
        content: "\f021";margin-right: 5px;
    }
    .ce_text {
        margin-bottom: 15px;
    }
}


// ----------------------------------------------------
// ----------------- MODULE FAQ - LESER ---------------
// ----------------------------------------------------
.mod_faqpage {
    margin-bottom: 60px;

    h2 {
        display: none;
    }
    section {
        margin-bottom: 40px;
    }

    p.toplink:before {
        font-family: FontAwesome;
        font-size: 18px;
        content: "\f0aa";margin-right: 5px;
    }
}










// ----------------------------------------------------
// ---------------- MODULE - EVENTLIST ----------------
// ----------------------------------------------------

.mod_eventlist {
    margin-bottom: 40px;


    // --- Teaser-Form ---
    .teaser-item {
        margin-bottom: 20px;
        padding-bottom: 20px;
    }

    // -------------------------- Eventlist-item --------------------------
    // Darstellung einer Event-Liste (z.B. im Seitenbereich)
    .list-item {
        margin-bottom: 20px;

        .info {
            margin-bottom: 0px;
            line-height: 100%;
            border-bottom: none;
            font-size: 14px;

            time:before {
                //content: "\f073"; Kalender
                content: "\f039"; // Textlinien
            }
        }

        h4 {
            margin-top: 5px;
            font-size: 18px;
            font-weight: 400;
        }

        .text {
            margin-top: 10px;
            color: $gray4;
        }


        // ------------- Style 1 -------------
        // Darstellung von Datum, Veranstaltungsort und Überschrift
        &.style1 {
            border-left: 8px solid $lightgray3;
            padding-left: 10px;
            padding-top: 3px;
        }

        // ------------- Style 2 -------------
        // Darstellung von Datum, Zeit, Veranstaltungsort und Überschrift
        // Datum als Kalenderblatt

        &.style2 {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding-bottom: 20px;
            padding-top: 0px;

            h4 {
                margin-top: 0px;
            }

            .inner {

            }
            .info {
                margin-top: 3px;
                padding-left: 0px;

                .day, .time, .location {
                    color: $gray7;
                    &:before {
                        color: $gray7;
                    }
                }

                .day:before {
                    font-family: FontAwesome;
                    content: "\f006"; // Stern
                    padding-right: 5px;
                }

                .location {
                    display: block;
                    padding-left: 0px;

                    &:before {
                        padding-right: 4px;
                        margin-left: 2px;
                    }
                }
            }


            .event-date{
                width:10%;
                float:left;
                margin-right:4%;
            }

            .event-detail{
                float: left;
                width: 86%;
                margin-right: 0%
            }
            .event-date {
                text-transform: uppercase;
                color: $gray3;
                text-align: center;
                padding: 0px;

                .date{
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 900;
                    margin-bottom: 0;
                    display: block;
                    margin-top: 2px;
                }
                .month,
                .year{
                    font-size: 12px;
                    font-weight: 700;
                    letter-spacing: 2px;
					text-indent: 2px;
                    color: $gray9;
                    display: block;
                    margin-top: 0px;
                }
                .year{
                    font-size:11px;
                    margin-top:0;
                    margin-bottom:2px;
                }
            }



        }
    }
}



// ----------------------------------------------------
// ----------------- MODULE - NEWSLIST ----------------
// ----------------------------------------------------
.mod_newslist {
    margin-bottom: 40px;

    a {
        color: $gray4;
    }

    // -------------------------- News-Teaser --------------------------
    // Standard-Darstellung von News Anlesetexten
    .teaser-item {
        margin-bottom: 40px;
        padding-bottom: 40px;
        border-bottom: 1px solid $black10;

        .inner {
            .info {
                margin-bottom: 0.5rem;
            }
        }
    }

    // -------------------------- News-Card --------------------------
    // Standard-Darstellung von News Anlesetexten
    .teaser-card {
        p.info {
            padding-left: 30px;
            svg {
                top: 2px;
            }
        }
    }

    // -------------------------- Newslist_item --------------------------
    // Darstellung einer News-Liste (z.B. im Seitenbereich)
    .list-item {
        margin-bottom: 20px;

        .info {
            margin-bottom: 0px;
            padding-left: 0px;
            line-height: 100%;
            border-bottom: none;
            font-size: 14px;
        }

        h4 {
            margin-top: 5px;
            font-size: 18px;
            font-weight: 500;
        }

        .text {
            margin-top: 10px;
            color: $gray4;
        }


        // ------------- Style 1 -------------
        // Darstellung von Datum und Überschrift
        &.style1 {
            border-left: 8px solid $lightgray3;
            padding-left: 10px;
            padding-top: 3px;
        }

        // ------------- Style 1 -------------
        // Darstellung von kleinem Thumbnail, Datum und Überschrift'
        // Dartsellung von Teaser-Text in mobiler Ansicht

        &.style2 {
            border-bottom: 1px solid $black10;
            padding-bottom: 20px;
            padding-top: 3px;

            .image-wrapper {
                .image_container {
                    img {
                        width: 100%;
                        height: auto;
                    }
                }

            }
        }

        @media(max-width: 480px) {
            .text-wrapper {
                padding-top: 20px;
            }
        }
    } // Ende .newslist-item



}

// ----------------------------------------------------
// ---------------- MODULE - NEWSREADER ---------------
// ----------------------------------------------------
.mod_newsreader {
    margin-bottom: 40px;

    .teaser {
        font-weight: 600;
    }

    .ce_comments {
        margin-top: 40px;
        padding-top: 40px;
        border-top: 1px solid $lightgray4;

        > h2 {
            display: none;
        }

        .comment_default.first:before{
            content: "Kommentare";
            @extend h3;
            position: relative;
            top: -10px;

        }

        .comment_default {
            margin-bottom: 30px;

            &.first {
                margin-top: 20px;
            }

            p.info {
                padding-left: 0px;

                &:before {
                    content: "\f0e5";
                }

                time:before {
                    position: relative;
                    margin-right: 5px;
                }
                a {
                    padding-left: 0px;
                    font-size: 12px;
                    &:hover {
                        margin: 0px;
                    }
                }
                a:before {
                    display: none;
                }
            }

            .comment {
                position: relative;
                top: 5px;
                padding: 20px;
                background: $lightgray4;
                margin-bottom: 20px;

                &:after {
                    bottom: 100%;
                    left: 40px;
                    border: solid transparent;
                    content: " ";
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                    border-bottom-color: $lightgray4;
                    border-width: 10px;
                    margin-left: -10px;
                }

                p {
                    font-size: 90%;
                    color: $gray7;
                }
            }


            .reply {
                margin: 0px;
                padding-left: 30px;
                margin-bottom: 30px;

                p.info {
                    font-weight: 600;
                    font-size: 12px;
                }

                .comment {
                    background: $lightgray2;
                    &:after {
                        border-bottom-color: $lightgray2;
                    }
                }

            }
        }
        form {
            margin-top: 60px;

            &:before{
                content: "Kommentar schreiben";
                @extend h3;
                margin-top: 60px;
            }
        }

    }
}


// ----------------------------------------------------
// ---------------- MODULE - BREADCRUMB ---------------
// ----------------------------------------------------

body.home .mod_breadcrumb {
  display: none;
}

.mod_breadcrumb {
    border-top: 1px solid #fff;
	margin: 0;
	background-color: $color1;
	color: #fff;
	font-size: 12px;

	ul {
		list-style: none;
		margin: 0;
        padding: 22px 0px;

		li {
            line-height: normal;
            position: static; // da bei position relative nicht klickbar
            a {
                float: left;
                line-height: normal;
                color: #fff;
                text-decoration: none;
                padding: 0 5px 0 0;

                &:after {
                    content: " > ";
                    color: #fff;
                }
            }
        }
	}
}

@media (max-width: 768px) {
	.mod_breadcrumb {

	}
}



// ----------------------------------------------------
// ----------------- MODULE - SITEMAP -----------------
// ----------------------------------------------------
.mod_sitemap {

    .submenu {
        margin-bottom: 30px;
    }

    ul {
        margin: 0 0 25px 0;
        padding: 0;
        list-style-type: none;
        line-height: 28px;

        li {
            position: relative;
            line-height: 28px;

            &:before {
                font-family: 'FontAwesome';

                color: $color1;
                position: absolute;
                top: 0;
                left: 2px;
                margin-right: 8px;
                font-style: normal;
            }
        }

        &.level_1 > li {
            padding: 0 0 0 25px;
            margin-left: 15px;

            .submenu {
                font-weight: 500;
            }

            &:before {
                font-size: 16px;
                content: '\f054';

                // rundes Icon
                color: #fff;
                background: $color3;
                border-radius: 100%;
                width: 30px;
                height: 30px;
                line-height: 30px;
                padding-left: 11px;
                margin-left: -15px;
            }
        }

        &.level_2 > li {

            padding: 0 0 0 20px;
            margin-left: 0px;

            &:before {
                font-size: 18px;
                content: '\f105';
            }
        }

    }
}


// ----------------------------------------------------
// ------------------ MODULE ARTICLE ------------------
// ----------------------------------------------------

/* spezielle Formatierungen */

.mod_article {

	/* --- hervorgehobener Artikel --- */
	&.highlighted {
		.ce_text {
			h3 {
				font-size: 22px;
				color: $color1;
				font-weight: 400;
				margin-top: 0px;
			}
			p {
				font-size: 18px;
			}
		}

		@media (max-width: 768px) {
			.ce_text {
				h3 {
					margin-top: 20px;
				}
			}

		}
	}
}

// ----------------------------------------------------
// ------------- MODULE CUSTOM NAVIGATION -------------
// ----------------------------------------------------
// Default Settings für Customnavs

.mod_customnav {
	&.metanavi {
        ul {
            list-style-type: none;
            margin: 0px;
            padding: 0px;
        }

	}
}


// ----------------------------------------------------
// --------------- MODULE CHANGE LAGUAGE --------------
// ----------------------------------------------------

.mod_changelanguage {
	display: inline-block;

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;

		li {
			display: inline-block;
		}
	}
}

// ----------------------------------------------------
// --------------- MODULE REGISTRATION ----------------
// ----------------------------------------------------
// Formular-feld-Formatierungen sind bei ce_form zu finden

.mod_registration,
.mod_personalData,
.mod_changePassword {
    .formbody {
        margin-top: 1.5rem;
        fieldset {
            background: $lightgray4;
            margin-top: 0px !important;
            padding: 40px 0px;
            position: relative;

            &.captchaDetails {
                display: none;
            }

            legend {
                position: absolute;
                left: 0px;
                top: 0px;
                margin-bottom: 10px;
                padding: 10px 20px 10px 55px;
                background: $black5;


                &:before {
                    font-family: 'FontAwesome';
                    content: '\f040';
                    font-size: 1.5rem;
                    color: $color1;
                    position: absolute;
                    top: 10px;
                    left: 25px;
                    margin-right: 8px;
                    font-style: normal;
                }

            }

            .widget {
                margin: 30px 0px 0px;

                @media (min-width: 768px) {
                    width: 48%;
                    float: left;

                    &:nth-child(even) {
                        margin-right: 4%;
                    }
                }
            }
        }

    }

}










// --------------------------------------------------------------------------------------------------------
// ---                                                                                                  ---
// ---                                         CONTENT ELEMENTS                                         ---
// ---                                                                                                  ---
// --------------------------------------------------------------------------------------------------------

// wenn H3 im Text nach einem Absatz (p) auftaucht
p, ul {
    & + h3 {
        margin-top: 2rem;
    }
}

// ----------------------------------------------------
// ------------------- CE_ACCORDION -------------------
// ----------------------------------------------------

.ce_accordion {
    &.ce_text {
        margin-bottom: 10px;
    }
	.accordion {
		padding: 15px 20px;
		background: $lightgray4;;
		border-radius: 0px;
		margin-top: 5px;

        // Clearfix
        &:after {
            display: table;
            content: " ";
            clear: both;
        }
		img {
			border-radius: 0px;
		}
	}

	.toggler {
	    background: $lightgray1;
		border-radius: 0px;
		color: #ffffff;
		font-size: 16px;
		font-weight: 400;
		margin-top: 10px;
		padding: 10px 20px;
		outline: none;

		&:after {
			font-family: FontAwesome;
			content: "\f138";
			float: right;
			transition: all 0.2s ease-in-out;
		}
		&:hover:after,
		&.active:after {
			content: "\f138";
            transform: rotate(90deg);
            padding-right: 1px;

		}

        &:hover {
            background: $color1;
        }
	}

	h3 {
		color: $color1;
		font-size: 20px;
		font-weight: 600;
		text-transform: none;
	}
}

// ----------------------------------------------------
// ----------------- CE_GALLERY - DEFAULT -------------
// ----------------------------------------------------
.ce_gallery {
    margin-bottom: 40px;

    .item {
        padding: 0px 2px 2px 0px;



        .image_container {
			margin: 0px;
        }
    }
    &.style2 {
        .item {
            padding: 0px 10px 10px 0px;

            .image_container {
                border: 1px solid $lightgray3;
            }
        }
    }



    img.item {
        margin: 0px;
        padding: 0px;
		width: 100%;
		height: auto;
        filter: saturate(50%);
        -webkit-filter: saturate(50%);
        -moz-filter: saturate(50%);
        -o-filter: saturate(50%);
        -ms-filter: saturate(50%);

        &:hover {
            filter: saturate(100%);
            -webkit-filter: saturate(100%);
            -moz-filter: saturate(100%);
            -o-filter: saturate(100%);
            -ms-filter: saturate(100%);
            transition: all ease-in-out 0.4s;
            transform: scale(1.1);
        }
    }




    .style3 {
		.row > div {
			padding: 2px;
		}
		.image_columns {
			column-gap: 2px;
			column-count: 2;

			&.columns1 {
				column-count: 2;
			}

			&.columns2 {
				@media (min-width: 768px)  { column-count: 2; }
				@media (min-width: 992px)  { column-count: 2; }
				@media (min-width: 1200px) { column-count: 2; }
			}
			&.columns3 {
				@media (min-width: 768px)  { column-count: 3; }
				@media (min-width: 992px)  { column-count: 3; }
				@media (min-width: 1200px) { column-count: 3; }
			}
			&.columns4 {
				@media (min-width: 768px)  { column-count: 3; }
				@media (min-width: 992px)  { column-count: 4; }
				@media (min-width: 1200px) { column-count: 4; }
			}
			&.columns5 {
				@media (min-width: 768px)  { column-count: 3; }
				@media (min-width: 992px)  { column-count: 4; }
				@media (min-width: 1200px) { column-count: 5; }
			}
			&.columns5 {
				@media (min-width: 768px)  { column-count: 3; }
				@media (min-width: 992px)  { column-count: 4; }
				@media (min-width: 1200px) { column-count: 5; }
			}
			&.columns6 {
				@media (min-width: 768px)  { column-count: 3; }
				@media (min-width: 992px)  { column-count: 4; }
				@media (min-width: 1200px) { column-count: 6; }
			}
			&.columns7 {
				@media (min-width: 768px)  { column-count: 3; }
				@media (min-width: 992px)  { column-count: 4; }
				@media (min-width: 1200px) { column-count: 7; }
			}
			&.columns8 {
				@media (min-width: 768px)  { column-count: 3; }
				@media (min-width: 992px)  { column-count: 6; }
				@media (min-width: 1200px) { column-count: 8; }
			}

		}

        .item {
			display: inline;
			padding: 0px;
			margin-bottom: 2px;
        }
    }
}

// ----------------------------------------------------
// ---------------- CE_IMAGE - DEFAULT ----------------
// ----------------------------------------------------


.ce_image {
	figure {
		&.image_container {
			img {
				width: auto;
				height: auto;
			}
		}
	}

	&.no-overlay {
		figure {
			&.image_container {
				a {
					&:after {
						display: none;
					}
				}
			}
		}
	}

	&.w-100 {
		figure {
			&.image_container {
				a {
					display: block;
				}
				img {
					width: 100%;
				}
			}
		}

	}

}


// ----------------------------------------------------
// ----------------- CE_TEXT - DEFAULT ----------------
// ----------------------------------------------------

.ce_text {
    margin-bottom: 40px;

	&:after {
		content: " ";
		visibility: hidden;
		display: block;
		clear: both;
	}

	a {
		color: $color1;
		&:hover {
			text-decoration: underline;
		}
	}

	em {
		color: $color1;
	}

	table td {
		vertical-align: top;
	}

    ul {
        margin: 0 0 25px 0;
        padding: 0;
        list-style-type: none;
        line-height: 28px;

        li {
            padding: 0 0 0 18px;
            margin-left: 15px;
            position: relative;
            line-height: 28px;

            #main & {
                &:before {
                    font-family: 'FontAwesome';
                    content: '\f054';
                    font-size: 12px;
                    color: $color1;
                    position: absolute;
                    top: 0;
                    left: 2px;
                    margin-right: 8px;
                    font-style: normal;
                }

                ul {
                    li {
                        &:before {
                            color: $color3;
                        }
                    }
                }
            }


        }
    }


}

// ----------------------------------------------------
// --------------------- CE_PLAYER --------------------
// ----------------------------------------------------

.ce_player {
    margin-bottom: 40px;
}


.iframe, .me-plugin, .mejs-container {
    width: 100% !important;
}


// ----------------------------------------------------
// ---------------- CE_TEXT - INFO-BOX ----------------
// ----------------------------------------------------

.ce_text.info-box {
	border-left: 1px solid #ddd;
	/*background: rgba(0,0,0,0.1);*/


	.inner {
		padding: 20px;
	}

	h3 {
		//color: $gray4;
		font-weight: 400;
		border-bottom: 1px solid $lightgray1;
		margin: 0px 0px 10px;
	}

	@media (max-width: 991px) {
		margin-bottom: 30px;
        border-left: none;
        .inner {
            padding: 20px 0px;
        }
	}
	@media (max-width: 767px) {
		min-height: inherit;
	}

}

aside {
	.ce_text.info-box {
		min-height: inherit;
	}
}



// ----------------------------------------------------
// --------------------- CE_TABLE ---------------------
// ----------------------------------------------------

.ce_table {
    margin-bottom: 40px;

	table {
		width: 100%;
		border-collapse: collapse;
		border: 1px solid $lightgray2;

        caption {
            text-align: left;
            margin-bottom: 5px;
            font-style: italic;
            color: $gray9;

        }
        th {
            background: $lightgray4;
            font-weight: 500;
        }

        tfoot {
            tr {
                background: lighten($lightgray4,3);
            }
        }


		th,
		td {
			padding: 17px !important;
			border-bottom: 1px dotted rgba(0, 0, 0, 0.1);
		}
		tr {
			transition: background ease-in-out 0.2s;

		}
	}

	&.table-price {
		margin-bottom: 40px;

		table {
			background: transparentize($gray4, 0.8);
			td.col_last {
				min-width: 100px;
				text-align: right;

			}
		}

	}
}






// ----------------------------------------------------
// ---------------------- CE_LIST ---------------------
// ----------------------------------------------------
.ce_list {
    margin-bottom: 40px;

    ul {
        margin-left: 10px;

        li {
            @extend .list_item;
			@extend .symb-standard;

            &:before {
                @extend .list_item_before;
                color: $color1;
            }
        }
    }

    ol {
        li {
            @extend .list_item;
            padding: 0px;
            margin-left: 0px;
        }
    }

}


// ----------------------------------------------------
// ------------------- CE_HYPERLINK -------------------
// ----------------------------------------------------
.ce_hyperlink {
    margin-bottom: 40px;

    a.hyperlink_txt {
        @extend .list_item;
        &:before {
            content: "\f08e";
            @extend .list_item_before;
            top: -3px;
        }
    }


	figure {
		&.image_container {
			img {
				width: auto;
				height: auto;
			}
		}
	}
}




// ----------------------------------------------------
// ---------------- CE_DOWNLOADS - DEFAULT ------------
// ----------------------------------------------------


.ce_downloads {
    margin-bottom: 40px;

	// Font-Symbole (global definiert list-font)
	&.style1 {
		.list-font {
			li {
				&:before {
					//deaktivieren, um Extension-Symbole darzustellen (gleiche wie bei .article)
					@extend .symb-download;
				}
			}
		}
	}

	// Icon-Symbole (global definiert list-img)
	&.style2 {
	}

}


// ----------------------------------------------------
// ---------------- CE_dlh_GOOGLEMAPS -----------------
// ----------------------------------------------------

.ce_dlh_googlemaps {
	.dlh_googlemap {
		width: 100% !important;
	}
}


// --------------------------------------------------------------------------
// ------------------------------- CE_SLIDER --------------------------------
// --------------------------------------------------------------------------
$slider-height: 600px;
$slider-height-tablet: 450px;
$slider-height-phone: 300px;


.ce_sliderStart,
.ce_faderStart {

    .content-slider,
    .content-fader {
        .slider-wrapper,
        .fader-wrapper {
            .image-banner {
                opacity: 0;
                z-index: 900;
                &.active {
                    opacity: 1;
                    z-index: 901;
                }
            }

        }
    }

    .slider-control,
    .fader-control {
        height: auto;

        // indicators
        .slider-menu,
        .fader-menu {
            top: -30px!important;
            font-size: 40px;
            b {
                color: $white60;
                font-weight: normal;
                /* change Bullet-Sign against FA-Icon
                &:before {
                    font-family: fontawesome;
                    content: "\f04d";
                    font-size: 16px;
                }
                */
                &.active {
                    color: #fff;
                }

            }
        }

        // controls
        a.slider-prev,
        a.fader-prev,
        a.slider-next,
        a.fader-next {
            top: -$slider-height/2-35;
            visibility: hidden;
            width: 50px;

            &:after {
                visibility: visible;
                display: block;
                font-family: 'FontAwesome';
                color: $color1;
                background: $white70;
                height: 50px;
                width: 50px;
                line-height: 50px;
                font-size: 30px;
                border-radius: 50%;
                transition: all 0.2s ease-in-out;
            }

            &:hover {
                &:after {
                    background: #fff;
                }
            }

            @media (max-width: 480px) {
                display: none;
            }
        }

        a.slider-prev,
        a.fader-prev {
            left: 20px;
            &:after {
                content: '\f104';
                text-indent: 17px;
            }
        }
        a.slider-next,
        a.fader-next {
            right: 20px;
            &:after {
                content: '\f105';
                text-indent: 20px;
            }
        }
    }

	@media (max-width: 991px) {
        .slider-control,
        .fader-control {
            a.slider-prev,
            a.fader-prev,
            a.slider-next,
            a.fader-next {
                top: -$slider-height-tablet/2-45;
            }

        }
    }

    @media (max-width: 767px) {
        .slider-control,
        .fader-control {
            a.slider-prev,
            a.fader-prev,
            a.slider-next,
            a.fader-next {
                top: -$slider-height-phone/2-55;
            }

        }
    }
}

// --------------------------------------------------------------------------
// ------------------------------- CE_YOUTUBE --------------------------------
// --------------------------------------------------------------------------


.ce_youtube,
.ce_vimeo {
    position: relative;

	.video_container {
		position: relative;
		padding-bottom: 56.25%;
		padding-top: 30px; height: 0; overflow: hidden;
        img {
            width: 100%;
            height: auto;
            position: absolute;
            top: 0px;
            &:hover {
                transform: scale(1.1);
            }
        }

		iframe,
		object,
		embed {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		iframe {
			border: 0px;
		}
	}
}


// --------------------------------------------------------------------------------------------------------
// ---                                                                                                  ---
// ---                                     CUSTOM CONTENT ELEMENTS                                      ---
// ---                                                                                                  ---
// --------------------------------------------------------------------------------------------------------


ul {
	&.icon-list {
		padding: 0;
		list-style-type: none;

		li {
			position: relative;
			display: flex;
			align-items: center;
			&.two-lines {
				align-items: start;
			}
			.text-center & {
				justify-content: center;
			}
			.text-right & {
				justify-content: flex-end;
			}
		}

		$iconsize : 1.25rem;
		$iconsizefooter: $iconsize*0.7;



		&:before,
		li:before {
			font-family: FontAwesome;
			color: $gray4;
			font-size: $iconsize;
			width: $iconsize;
			text-align: center;
			margin-right: $iconsize/2;

			footer & {
				font-size: $iconsizefooter;
				width: $iconsizefooter;
				margin-right: $iconsizefooter/2;
			}
		}

		&.contact {
			li.person:before  { content: "\f2bd";}
			li.address:before { content: "\f041";}
			li.phone:before   { content: "\f095";}
			li.mobile:before  { content: "\f10b"; font-size: $iconsize*1.25; footer & {font-size: $iconsizefooter*1.25;}}
			li.fax:before     { content: "\f1ac"; font-size: $iconsize*0.9;  footer & {font-size: $iconsizefooter*0.9;}}
			li.email:before   { content: "\f003"; font-size: $iconsize*0.9;  footer & {font-size: $iconsizefooter*0.9;}}
			li.link:before    { content: "\f08e";}
		}

		&.open {
			&:before {
				//content: "\f017";
			}
		}
	}


	&.social {
		margin: 0px;
		padding: 0px;

		li {
			display: inline-block;
			margin-right: 15px;

			.fa {
				font-size: 30px;
				color: $gray4;
				transition: ease-in-out all 0.2s;

				&.fa-facebook-official {
					color: #3b5998;
				}
				&.fa-google-plus-official {
					color: #dd4b39;
				}
				&.fa-linkedin-square {
					color: #0077b5;
				}
				&.fa-pinterest {
					color: #bd081c;
				}
				&.fa-twitter-square {
					color: #1da1f2;
				}
				&.fa-xing-square {
					color: #026466; // kiwi: #cfdc00
				}
				&:hover {
					color: $gray4;
				}

			}
		}
	}
}






.address-box {
	// -------------------------- ADDRESS-BOX Style 3 --------------------------
	// Adress-Karten zum Aufklappen

	position: relative;


	&.style1 {
		margin-bottom: 30px;

		.address {
			span {
				display: block;

				&.addressname {
					font-weight: 600;
				}
				&.street {}
				&.city {}
			}
		}


		.opening-hours {
			background: $lightgray4;
			.open-item {
				clear: both;
				span {
					float: left;
					&.day {
						width: 150px;
					}
				}
			}
		}


		.more-button {
			position: absolute;
			right: 1.5rem;
			top: 1.5rem;
			z-index: 1;
			width: 40px;
			height: 40px;
			background: $color2;
			color: #fff;
			transition: all 0.4s ease-in-out;

			&:after {
				position: relative;
				font-family: 'FontAwesome';
				content: "\f078";
				font-size: 30px;
				line-height: 38px;
				padding: 5px;
			}

			&:hover {
				cursor: pointer;
				background: $gray4;
			}

			&.open {
				&:after {
					position: relative;
					font-family: 'FontAwesome';
					content: "\f00d";
					font-size: 30px;
					line-height: 38px;
					padding: 9px;
				}
			}

		}

		.more-infos {
			display: none;

			.info-wrapper {
				opacity: 0;
				transition: all 0.4s ease-in-out;

			}

			&.open {
				.info-wrapper {
					opacity: 1;
				}

			}
		}
	}
}

// ----------------------------------------------------
// ---------------------- BUTTON ---------------------
// ----------------------------------------------------
.button {
    .btn {
		span {
			&:before {
				font-family: 'FontAwesome';
				content: attr(data-fa-icon);
			}
		}
    }
}

// -----------------------------------------------------
// ----------------------- CARDS -----------------------
// -----------------------------------------------------

.card {
	border-radius: 0px;

	[class*='card-img'] {
		border-radius: 0px;
	}

	@media screen and (max-width: 768px) {
		.image_container {
			width: 100% !important;
		}
	}

	.cboxElement {
		display: block;
	}

	&.round-image  {
		.image-wrapper {
			img,
			.cboxElement:after {
				border-radius: 50%;
			}
		}
	}

	.contact {
		margin-bottom: 0px;
		li {
			border-bottom: 0px solid $lightgray2;
			padding-top: 5px;
			//padding-bottom: 8px;
            &.address {
                margin-bottom: 5px;
                &:before {
                    content: none !important;
                }
            }
		}
	}

	.card-header,
	.card-footer {
		background: transparent;
		border: none;
		padding: 0px;
	}

	.card-body {
		@media screen and (max-width: 768px) {
			width: 100% !important;
		}

		.card-title {
			margin-bottom: 0.5rem;
		}
		.card-subtitle {
			color: $gray8;
		}


		.open {
			margin-top: 10px;
            margin-bottom: 0px;

            .open-item {
                border-top: 1px solid transparentize($gray10, 0.7);
                margin-top: 5px;
                padding-top: 5px;
            }


		}

		.text {
			p:last-child {
				margin-bottom: 0px;
			}
		}
	}


	&.text-center { // in Kombination mit rundem Bild, zentrierte Ausrichtung des gesamten Card-Content
		ul.contact {

			li {
				padding-left: 0px;

				&:before {
					position: relative;
					margin-right: 5px;
					left: 0px;
				}
			}
		}

		.social {
			li {
				margin: 0px;
				padding: 0px 5px;
			}
		}
	}


	&.style3 {
		.card-footer {
			bottom: 0px;
		}
	}


	// ----------------------- CARDS TOPIC -----------------------
	&.topic {
		overflow: hidden;

		.card-title {
			a {
				color: inherit;
				transition: none;
			}

		}
		.card-body {
			p {
				margin-bottom: 0.5rem;
			}
		}

		&.boxlink {
			transition: all ease 0.2s;
			// hover-settings in _settings_colors
		}



		&.style5 {
			.layer-1 {
				background: $black30;
				backdrop-filter: blur(2px); //brightness (0.5);
				border: 1px solid $white30;
				margin: 1.5rem;
				opacity: 1;
				transition: all 0.4s ease-in-out;
				z-index: +1;
			}
			&:hover,
			&:active {
				-webkit-user-select: none;
				-webkit-touch-callout: none;

				.layer-1 {
					margin: 0;
					backdrop-filter: blur(5px); //brightness (0.5);
				}
			}
		}


		&.style6, &.style7 {
			.layer-1 {
				background: $black30;
				backdrop-filter: blur(2px); //brightness (0.5);
				border: 1px solid $white30;
				margin: 1.5rem;
				opacity: 1;
				transition: all 0.4s ease-in-out;
			}
			.layer-2 {
				background: $black50;
				backdrop-filter: saturate(0.5);
				border: 1px solid $white30;
				margin: 1.5rem;
				opacity: 0;
				top: 100px;
				transition: all 0.3s ease-in-out;
			}
			&:hover,
			&:active {
				-webkit-user-select: none;
				-webkit-touch-callout: none;

				.layer-1 {
					left: -100px;
					opacity: 0;
				}
				.layer-2 {
					opacity: 1;
					top: 0;
				}
			}
		}
		&.style7 {
			.layer-1 {
				top: unset;
			}
			&:hover,
			&:active {
				.layer-1 {
					bottom: -30px;
					left: 0;
					opacity: 0;
				}
			}
		}

	} // end .topic
}




// ----------------------------------------------------
// ---------------------- DIVIDER ---------------------
// ----------------------------------------------------
.divider {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;
    margin-top: 40px;
    z-index: 99;
    clear: both;
	width: 100%;
}


// ----------------------------------------------------
// ----------------------- LEAD -----------------------
// ----------------------------------------------------

.lead {
	display: inline-block;
	width: 100%;
	font-size: 1.25rem;

	$underline: 280px;

	h1,h2 {
        position: relative;
        &:after {
            content: url(/files/kripps_theme/img/underline.svg);
            left: 50%;
            display: block;
            height: 15px;
            margin: 0 0 0 -1 * $underline / 2;
            position: absolute;
            bottom: 0px;
            width: $underline;
        }
		&.text-white {
			&:after {
				content: url(/files/kripps_theme/img/underline_white.svg);
			}
		}
	}

    .text {
        margin-top: 25px;
    }

    .text-wrapper {
		&.max800 {
			max-width: 800px;
		}
        line-height: 150%;
    }


	// -------------------------- H1/h2-after settings --------------------------
	&.style1,
	&.style3,
	&.style5 {
		@media(min-width: 576px) {
			h1,h2 {
				&.left,
				&.block {
					&:after {
						left: $underline / 2;
					}
				}
				&.right {
					&:after {
						left: 100%;
						margin-left: -1 * $underline;
					}
				}
			}
		}
	}
	&.style4,
	&.style6 {
		@media(min-width: 768px) {
			h1,h2 {
				&.left,
				&.block {
					&:after {
						left: $underline / 2;
					}
				}
				&.right {
					&:after {
						left: 100%;
						margin-left: -1 * $underline;
					}
				}
			}
		}
	}
}




// --------------------------------------------------------------------------
// -------------------------------- ICON-TEXT --------------------------------
// --------------------------------------------------------------------------

.icon-text {
    transition: all 0.2s ease-in-out;
    margin-bottom: 40px;

    .link {
        display: block;
        height: 100%;
        width: 100%;
    }

    .image-wrapper {
        padding: 20px 20px 0px;
    }
    .icon {
        span {
            display: inline-block;
            &:before {
                font-family: 'FontAwesome';
                content: attr(data-fa-icon);
            }
            &.round-icon {
                border-radius: 50%;
            }
        }
    }


    @media(max-width: 992px) {
        margin-bottom: 30px;
    }

    // -------------------------- ICON-TEXT Style 1 --------------------------
    // Icon wird standardmäßig links neben der Überschrift und dem Text platziert
    // bei zentriertem Text wird das Icon darüber platziert
    // optinal kann beim Icon der Hintergrund ausgeschaltet werden

    &.style1 {
        .icon {
            border-radius: 2px;
            padding: 10px;
            width: 60px;
            height: 60px;
            background: $color1;
            color: #fff;
            text-align: center;
            line-height: 40px;
            font-size: 30px;

            &.transparent {
                background: transparent;
                color: $color1;
                font-size: 60px;
                padding: 0px;
            }
			&.round-icon {
				border-radius: 50%;
			}

			&.white {
				background: #fff;
				color: $color1;
				&.transparent {
					background: transparent;
					color: #fff;
				}
			}

			&.color1 {
				background: $color1;
				color: #fff;
				&.transparent {
					background: transparent;
					color: $color1;
				}
				&.invert {
					background: #fff;
					color: $color1;
				}
			}

			&.color2 {
				background: $color2;
				color: #fff;
				&.transparent {
					background: transparent;
					color: $color2;
				}
				&.invert {
					background: #fff;
					color: $color2;
				}
			}
			&.color3 {
				background: $color3;
				color: #fff;
				&.transparent {
					background: transparent;
					color: $color3;
				}
				&.invert {
					background: #fff;
					color: $color3;
				}
			}





        }
        &.left-aligned {
            .icon {
                float: left;
            }
            .text-wrapper {
                padding-left: 80px;

            }
        }
        &.center-aligned {
            text-align: center;
            .icon {
                margin: 0px auto 10px;
            }
        }

    }
}


// --------------------------------------------------------------------------
// -------------------------- BOOTSTRAP CAROUSEL ----------------------------
// --------------------------------------------------------------------------
.carousel {
	// Carousel fade transition
	&.carousel-fade {
		.carousel-item {
			opacity: 0;
			transition-duration: .6s;
			transition-property: opacity;
			&.active {
				opacity: 1;
			}
		}
		.carousel-item-next.carousel-item-left,
		.carousel-item-prev.carousel-item-right {
			opacity: 1;
		}
		.active {
			&.carousel-item-left, &.carousel-item-right {
				opacity: 0;
			}
		}
		.carousel-item-next,
		.carousel-item-prev,
		.carousel-item.active {
			transform: translateX(0);
			transform: translate3d(0, 0, 0);
		}
		.active {
			&.carousel-item-left, &.carousel-item-prev {
				transform: translateX(0);
				transform: translate3d(0, 0, 0);
			}
		}
	}

	// Indicators
	.carousel-indicators {
		li {
			&.active {
			}
		}

		@media (max-width: 767px) {
			display: none;
		}
	}

	.carousel-control-next-icon {
        background-image: url(/files/kripps_theme/icons/thin-right_white.svg);
        background-size: 100%;
        width: 80px;
        height: 250px;
        fill: white;

    }
	.carousel-control-prev-icon {
        background-image: url(/files/kripps_theme/icons/thin-left_white.svg);
        background-size: 100%;
        width: 80px;
        height: 250px;
        fill: white;

    }


}


// --------------------------------------------------------------------------
// ----------------------------- IMAGE-BANNER  ------------------------------
// --------------------------------------------------------------------------

// fluid + fixed versions

$banner-height-small:           250px;
$banner-height-normal:          400px;
$banner-height-wide:            600px;
$banner-height-wide-tablet:		$slider-height-tablet;
$banner-height-wide-phone:      $slider-height-phone;

$underline: 280px;

.image-banner {

	// fixed version with trigger-points
	&.fixed {

		position: relative;

		&.small {
			height: $banner-height-small;

			@media (max-width: 991px) {
				height: $banner-height-small;
			}
			@media (max-width: 767px) {
				height: $banner-height-small - 50px;
			}
			@media (max-width: 575px) {
				height: $banner-height-small - 100px;
			}
		}

		&.normal {
			height: $banner-height-normal;

			@media (max-width: 991px) {
				height: $banner-height-normal - 100px;
			}
			@media (max-width: 767px) {
				height: $banner-height-normal - 200px;
			}
			@media (max-width: 575px) {
				height: $banner-height-normal - 250px;
			}
		}
		&.wide {
			height: $banner-height-wide;
			@media (max-width: 1199px) {
				height: $banner-height-wide - 100px;
			}
			@media (max-width: 991px) {
				height: $banner-height-wide - 200px;
			}
			@media (max-width: 767px) {
				height: $banner-height-wide - 400px;
			}
			@media (max-width: 575px) {
				height: $banner-height-wide - 450px;
			}
		}
	}

	// Fluid-Version
	&.fluid {
		img {
			width: 100%;

			@media (max-width: 767px) {
				width: auto;
				height: 240px;
				margin-left: 50% ;
				transform: translateX(-50%);
			}
			@media (max-width: 575px) {
				height: 200px;
			}

		}
	}

	.inner { // fluid versions
		width: 100%;
		margin: auto;
		top: 0px;
		height: 100%;
	}


	.title {
		@extend .h1;
		display: block;
		position: relative;
		padding-bottom: 1rem;
		margin-bottom: 1rem;
		font-size: 3.5rem;
		//text-transform: uppercase;

		@media (max-width: 1199px) {
			font-size: 2.6rem;
		}

		@media (max-width: 991px) {
			font-size: 2.2rem;
		}
		@media (max-width: 767px) {
			font-size: 2rem;
		}
		@media (max-width: 575px) {
			font-size: 1.8rem;
		}


        &:after {
            content: url(/files/kripps_theme/img/underline_dark.svg);
            left: 50%;
            display: block;
            height: 15px;
            margin: 0 0 0 -1 * $underline / 2;
            position: absolute;
            top: 35px;
            width: $underline;
            @media (max-width: 767px) {
                top: unset;
            }

        }
        &.text-white {
            &:after {
                content: url(/files/kripps_theme/img/underline_white.svg);
            }
        }
	}

	.subtitle {
		@extend .h1;
		display: block;
		padding: 0px;
		font-weight: 300;
		font-size: 1.8rem;
		text-transform: none;
        font-family: 'Lato';
        letter-spacing: 1px;

		@media (max-width: 1199px) {
			font-size: 1.2rem;
			line-height: 1.5rem;
		}

		@media (max-width: 991px) {
			font-size: 1rem;
			line-height: 1.5rem;
		}
	}

	.text {
        z-index: +1;
        color: $gray4;
	}

	.text-center {
		.title {
			&:after {
				left: 50%;
				margin-left: $underline / -2;
			}
		}
	}

	.text-left, .text-lg-left {
		.title {
			&:after {
				left: 0px;
				margin-left: 0px;
			}
		}
	}

	.text-lg-right {
		.title {
			&:after {
				right: 0px;
				left: initial;
				margin-right: 0px;
			}
		}
	}
    .box {
        z-index: +1;
    }

    .image-overlay {
        top: 0px;
    }


	// -------------------------- IMAGE_BANNER Style 1 & 2 --------------------------
	// Text in Box mit transparentem Hintergrund

	&.style1,
    &.style2 {

		@media (max-width: 575px) {
			.container {
				padding: 0px;
			}
		}
	}



	// -------------------------- IMAGE_BANNER Style 5 --------------------------
	// Text in Box mit transparentem Hintergrund und zusätzlichem Bild

	&.style5 {
		overflow: hidden;

		.title,
		.subtitle,
		.icon-text {
			//color: #fff;
			a:link {
				color: $gray4;
			}
		}

		.text-left,
		.text-lg-left {
			.title {
				&:after {
					@media (max-width: 767px) {
						left: 50%;
						margin-left: $underline / -2;
					}

				}
			}
		}
	}

    // -------------------------- IMAGE_BANNER Style 10 --------------------------
	// Text auf farbigem Hintergrund mit Schräge
	&.style8 {
		.content {
			z-index: 10;
			.inner {
				@media (max-width: 991px) {
					padding-left: 0px;
					padding-right: 0px;
				}
			}
		}

		.bgrnd-text {
			width: 100%;
			height: 100%;
			z-index: 2;
			top: 0;

			@media (min-width: 992px) and (max-width: 1199px) {
				width: 120%;
			}

			@media (min-width: 992px) {

				&.skew40left {
					transform: skewX(40deg);
				}
				&.skew40right {
					transform: skewX(-40deg);
				}

				&.shift-sm-left { left: -30%;}
				&.shift-md-left { left: -40%;}
				&.shift-lg-left { left: -50%;}
				&.shift-xl-left { left: -60%;}

				&.shift-sm-right { right: -30%;}
				&.shift-md-right { right: -40%;}
				&.shift-lg-right { right: -50%;}
				&.shift-xl-right { right: -60%;}
			}
			@media (max-width: 991px) {
				opacity: 0.9;
			}
		}



		.bgrnd-overlay {
			top: 0px;
			z-index: 1;

		}
		.bgrnd-image {
			top: 0px;
			z-index: 0;
			background-position-y: 0px;

			&.bg-left {
				left: 0px;
			}
			&.bg-right {
				right: 0px;
			}
			&.bg-center {
				left: 0%;
				right: 0%;
				margin: auto;
			}

		}

	}
}

// --------------------------------------------------------------------------
// ----------------------------- OPENING HOURS ------------------------------
// --------------------------------------------------------------------------

.opening-hours {

	.opening-hours-box {
		&.bgwhite {
			background: #fff;
		}

		&.bgcolor1 {
			background: darken($color1,20%);
			border: none;
			@extend .light-text;
		}

		&.bgcolor2 {
			background: darken($color2,30%);
			border: none;
			@extend .light-text;
		}
		&.bgcolor3 {
			background: $lightgray4;
			border: none;
		}

	}

	.open-item {
		clear: both;
		span {
			float: left;
			&.day {
				width: 150px;
			}
		}
	}
}



// -------------------------------------------------------
// --------------------- POPUP-BOX ----------------------
// -------------------------------------------------------
.popup-box {
    height: 100%;
    //width: %; // test safari
    position: relative;
    transition: all 0.4s ease-in-out;
    margin-bottom: 30px;

    //border: 1px solid #ccc;
    background: $color1;
    min-height: 150px;


    .inside {
        text-align: center;
        padding: 20px;
        display: table;
        height: 100%;
        width: 100%;
        a {
            display: table-cell;
            vertical-align: middle;
            color: $gray3;
            outline: 0;
            transition: all ease-out 0s;
        }
        h3 {
            margin-bottom: 0px;
        }
        h4 {
            font-size: 18px;
            font-weight: 600;
            margin: 15px 0px 0px;
            transition: all 0.4s ease-in-out;
        }
    }
    &:hover {
        background: $color2;
        .inside {
            h4, a {
                color: #fff;
            }
        }
    }
}

.lightbox-window {
    padding: 30px 100px;
    min-width: 600px;
    max-width: 800px;

        .image-wrapper {
            .image_container {
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }

        ul {
            margin: 0 0 25px 0;
            padding: 0;
            list-style-type: none;
            line-height: 28px;

            li {
                position: relative;
                margin-left: 25px;
            }
            li:before {
                position: absolute;
                font-family: FontAwesome;
                left: -20px;
                color: $color1;
            }
            li.link:before    { content: "\f08e";};
        }
}



// --------------------------------------------------------------------------
// --------------------------- STANDARD-ARTICLE  ----------------------------
// --------------------------------------------------------------------------

.article {
	margin-bottom: 60px;

	.downloadlist {
		.list-font {
			li {
				&:before {
					//deaktivieren, um Extension-Symbole darzustellen (gleiche wie bei ce_downloads)
					@extend .symb-download;
				}
			}
		}
	}


	@media(max-width: 575px) {
		.btn {
			width: 100%;
		}
	}



    // Style 2: Das Bild wird vom Text umflossen
    &.style2 {
		.image_container {
			width: inherit;

			@media(min-width: 992px) {
				margin: 0px 30px 20px 0px;

				&.float-right {
					margin: 0px 0px 20px 30px;
				}
			}
			@media(max-width: 991px) {
				margin: 0px 0px 20px 0px;
				width: 50% !important;

				&.w-100 {
					width: 100% !important;
				}

				&.float-right {
                    margin: 0px 0px 20px 30px;
                }
			}

			@media(max-width: 767px) {
				width: 100% !important;
			}
		}
    }

}
// ------------------------- ENDE STANDARD-ARTICLE  -------------------------



// --------------------------------------------------------------------------
// ---------------------------- IMAGE-TEXT-BLOCK ----------------------------
// --------------------------------------------------------------------------


.image-text-block {
    background: #f5f5f5;

    [class*='col'] {
		min-height: 400px;

		@media (min-width: 576px) {
			min-height: 300px;
		}


		@media (min-width: 769px) {
			min-height: 480px;
		}
	}

    .text-wrapper {
        display: table;
        height: 100%;
        width: 100%;

        .text {
            display: table-cell;
            vertical-align: middle;
            padding: 0px 60px;

            .inside {
				@media (min-width: 1200px) {
					width: 60%;
				}
            }


				ul {
					margin-left: 0px;

					li {
						padding-left: 35px;
						line-height: 2rem;


						&:before {
							@extend .symb-checked;
							color: $color2;
							font-size: 1.5rem;
						}
					}
				}

			.btn {
				margin-bottom: 10px;
			}
        }
    }

    .image {
        height: 100%;
        background-size: cover;
        background-position: 0 0;
        background-repeat: no-repeat;
        overflow: hidden;

        .overlay {
            background: $black50;
            height: 100%;
            width: 100%;
        }
    }
}
// ------------------------- ENDE IMAGE-TEXT-BLOCK --------------------------





// --------------------------------------------------------------------------
// -------------------------------- TIMELINE --------------------------------
// --------------------------------------------------------------------------
.timeline {
	margin:0;
	padding:0;
	list-style:none;
	position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 10px;
        background: $lightgray3;
        margin-left: -10px;
    }

    >li {
        position:relative;

        .tl-date {
            display: block;
            position: absolute;
            margin-top: 10px;

            span {
                display: block;
                color: $gray9;
                margin-top: -4px;
            }
        }
        .tl-label {
            position: relative;
            padding: 30px;

            &:after {
                position: absolute;
                top: 10px;
                right: 100%;
                height: 0px;
                width: 0px;
                content:    " ";
                border: solid transparent;
                border-width: 10px;
            }

            h3 {
            }

            .image {
                img {
                    width: 100%;
                }
            }
        }

        .tl-icon {
            span:before {
                font-family: 'FontAwesome';
                content: attr(data-fa-icon);

            }
            font-size:  22px;
            line-height: 40px;
            width: 40px;
            height: 40px;
            position: absolute;
            color: #fff;
            background: $color1;
            text-align: center;
            top: 0;
            margin: 0 0 0 -25px;
            border-radius: 50%;
            box-shadow: 0 0 0 8px $lightgray3;
        }
    }




	&.style1 {
		&:before {
			left: 20%;
		}

		>li {
			.tl-date {
				width: 25%;
				padding-right: 100px;

				span {
					text-align: right;
				}
			}

			.tl-label {
				margin: 0 0 15px 25%;
			}

			&:nth-child(odd) {
				.tl-label {
					background: #fff;
					border: 1px solid $lightgray1;

					&:after {
						border-right-color: $lightgray1;
					}
				}
			}
			&:nth-child(even) {
				.tl-label {
					background: $lightgray4;
					border: 1px solid $lightgray2;

					&:after {
						border-right-color: $lightgray2;
					}
				}
			}

			.tl-icon {
				left: 20%;
			}
		}

		@media (min-width: 768px) and (max-width: 991px) {
			&:before {
				left: 15%;
			}
			>li {
				.tl-icon {
					left: 15%;
				}
			}

		}


		@media (max-width: 767px) {
			&:before {
						display:none;
			}
			>li {
				.tl-date {
					width: 100%;
					position: relative;
					padding: 0 0 20px 0;
					font-size: 20px;

					span {
						 text-align:left;
					}
				}
				.tl-label {
					margin: 5px 0 50px 0;
					padding: 30px;

					&:after {
						right: auto;
						left: 68px;
						border-right-color: transparent;
						border-bottom-color: $lightgray2;
						top: -20px;
					}
				}

				&:nth-child(odd),
				&:nth-child(even) {
					.tl-label:after {
						border-right-color: transparent;
						border-bottom-color: $lightgray1;
					}
				}
				.tl-icon {
					position: relative;
					float: left;
					left: 60px;
					margin: -55px 5px 0 0px;
				}

			}

		}
	}

	&.style2 {
		&:before {
			left: 50%;
		}

		>li {
			.tl-date {
				width: 45%;

				span {
				}
			}

			&:nth-child(odd) {
				.tl-date {
					span {
						text-align: right;
					}
				}

				.tl-label {
					margin: 0 0 15px 55%; //+++

					&:after {
						right:  100%;
						border-right-color: $lightgray1;
					}
				}
			}

			&:nth-child(even) {
				.tl-date {
					right: 0px;

					span {
						text-align: left;
					}
				}

				.tl-label {
					margin: 0 55% 15px 0; //+++

					&:after {
						left:  100%;
						border-left-color: $lightgray1;
					}
				}
			}

			.tl-icon {
				left: 50%;
			}
		}

		@media (max-width: 991px) {
			&:before {
				display:none;
			}
			>li {
				.tl-date {
					width: 100%;
					position: relative;
					padding: 0 0 20px 0;
					font-size: 20px;
				}

				&:nth-child(odd),
				&:nth-child(even) {
					.tl-date {
						span {
							 text-align:left;
						}
					}

					.tl-label {
						margin: 5px 0 50px 0;

						&:after {
							right: auto;
							left: 68px;
							top: -20px;
							border-right-color: transparent;
							border-left-color: transparent;
							border-bottom-color: $lightgray1;
						}
					}

					.tl-icon {
						position: relative;
						float: left;
						left: 60px;
						margin: -55px 5px 0 0px;
					}

				}

			}

		}

	}


	&.style3 {
		&:before {
			left: 50%;
		}
		>li {
			.tl-date {
				position: static;
				margin-top: 0px;

				span {
					text-align: center;
					color: #fff;
				}
			}

			.tl-label {
				margin-bottom: 30px;
			}

			.tl-icon {
				position: relative;
				z-index: 1;
				float: none;
				height: 100px;
				width: 100px;
				left: 50%;
				margin: 0px 0px 30px -55px;
				span:before {
					position: relative;
					top: 10px;
				}
			}
		}
	}


}


// --------------------------- ENDE TIMELINE -------------------------------


















// --------------------------------------------------------------------------------------------------------
// ---                                                                                                  ---
// ---                           FORMULARE, LOGIN, REGISTRATION, NEWSLETTER                             ---
// ---                                                                                                  ---
// --------------------------------------------------------------------------------------------------------


// ----------------------------------------------------
// --------------------- FORMULAR ---------------------
// ----------------------------------------------------

.ce_form,
.mod_registration,
.mod_login,
.mod_lostPassword,
.mod_personalData,
.mod_search,
.mod_subscribe,
.mod_unsubscribe,
.form {
	.formbody {

		$border-fields: 1px solid $lightgray2;
		$border-fields-hover: 1px solid $color1;;

		fieldset {
			margin-top: 20px;
		}
		legend {
			width: 100%;
			padding: 0;
			margin-bottom: 0px;
			font-size: 16px;
			text-transform: uppercase;
			line-height: inherit;
			color: inherit;
			border: 0;
			color: $color1;
		}

        input[type="text"],
		input.text,
		select.select,
		textarea.textarea {
			padding: 6px 10px;
			border: $border-fields;
			color: $gray4;
            background: #fff;
            transition: all 0.4s ease-in-out;

			&:hover,
			&:focus,
			&:active {
				padding: 6px 10px;
				border: $border-fields-hover;
				outline: none;
			}
		}

		select.select {
			height: 45px;

			option {
				padding: 12px 0px;
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
			}
		}



		input:-webkit-autofill,
		textarea:-webkit-autofill,
		select:-webkit-autofill {
			background-color: rgba(255, 255, 255, 0.5);
			background-image: none;
			color: rgb(0, 0, 0);
		}

		.widget-captcha {
			.captcha {
				max-width: 170px;
			}

			span.captcha_text {
				float: left;
				padding-top: 10px;
				width: auto;
				color: $gray4;
			}
		}

        fieldset {
            &.agb {
                padding-left: 0px;
                input, label {
                    display: inline-block;
                }
                input {
                    top: 6px;
                    position: relative;
                }
            }
        }

        // Formatierung von conditional Checkboxes, da keine individuelle Klasse vergebbar
        input.checkbox{
            top: 6px;
            position: relative;
            display: inline-block;

            + label {
                display: inline-block;
            }
        }

        .widget-submit,
		.submit_container {
            margin-top: 20px;
            .submit {
                //@extend .btn.primary;
                border: 0px solid transparent !important;
            }

        }

		// Radio styling
		[type ="radio"]:checked,
		[type ="radio"]:not(:checked) {
			position          : absolute;
			left              : -9999px;
		}
		[type ="radio"]:checked + label,
		[type ="radio"]:not(:checked) + label
		{
			position          : relative;
			padding-left      : 28px;
			cursor            : pointer;
			line-height       : 20px;
			display           : inline-block;
			color             : #666;
		}
		[type ="radio"]:checked + label:before,
		[type ="radio"]:not(:checked) + label:before {
			content           : '';
			position          : absolute;
			left              : 0;
			top               : -2px;
			width             : 24px;
			height            : 24px;
			border            : 1px solid #999;
			border-radius     : 100%;
			background        : #fff;
		}
		[type ="radio"]:checked + label:after,
		[type ="radio"]:not(:checked) + label:after {
			content           : '';
			width             : 16px;
			height            : 16px;
			background        : $color2;
			position          : absolute;
			top               : 2px;
			left              : 4px;
			border-radius     : 100%;
			-webkit-transition: all 0.2s ease;
			transition        : all 0.2s ease;
		}
		[type ="radio"]:not(:checked) + label:after {
			opacity           : 0;
			-webkit-transform : scale(0);
			transform         : scale(0);
		}
		[type ="radio"]:checked + label:after {
			opacity           : 1;
			-webkit-transform : scale(1);
			transform         : scale(1);
		}


		// Checkbox styling
		[type ="checkbox"]:checked,
		[type ="checkbox"]:not(:checked) {
			position          : absolute;
			left              : -9999px;
		}
		[type ="checkbox"]:checked + label,
		[type ="checkbox"]:not(:checked) + label
		{
			position          : relative;
			padding-left      : 32px;
			cursor            : pointer;
			display           : inline-block;
			color             : #666;
		}
		[type ="checkbox"]:checked + label:before,
		[type ="checkbox"]:not(:checked) + label:before {
			content           : '';
			position          : absolute;
			left              : 0;
			top               : 0;
			width             : 24px;
			height            : 24px;
			border            : 1px solid #999;
			background        : #fff;
		}
		[type ="checkbox"]:checked + label:after,
		[type ="checkbox"]:not(:checked) + label:after {
			content           : '';
			width             : 16px;
			height            : 16px;
			background        : $color2;
			position          : absolute;
			top               : 4px;
			left              : 4px;
			-webkit-transition: all 0.2s ease;
			transition        : all 0.2s ease;
		}
		[type ="checkbox"]:not(:checked) + label:after {
			opacity           : 0;
			-webkit-transform : scale(0);
			transform         : scale(0);
		}
		[type ="checkbox"]:checked + label:after {
			opacity           : 1;
			-webkit-transform : scale(1);
			transform         : scale(1);
		}


	}

}

// ----------------------------------------------------
// -------- FORMULAR MODUL SUCHE / NEWSLETTER ---------
// ----------------------------------------------------
// Formatierungen für das Input-Feld bei Formulare
.mod_search,
.mod_subscribe,
.mod_unsubscribe  {
    margin-bottom: 40px;

    .formbody {
        position: relative;
        width: 100%;

        input[type="text"],
        input[type="search"]{
            height: 45px;
        }

        input[type="submit"],
		button[type="submit"] {
            border: 0px solid transparent !important;
            height: 45px;
            position: absolute;
            right: 0px;
            top: 0px;
        }


    }
}
// -------- FORMULAR MODUL SUCHE  ---------
.mod_search  {
    .formbody {
        padding-right: 55px;
        input[type="submit"] {
            width: 45px;
            font-family: 'FontAwesome';
            //icon im Layout festgelegt
        }

		&.icon {
            padding-right: 55px;
            input[type="submit"] {
                width: 45px;
                font-family: 'FontAwesome';
                //icon im Layout festgelegt
            }
        }

    }
}
// -------- FORMULAR MODUL NEWSLETTER  ---------
.mod_subscribe,
.mod_unsubscribe
  {
    .formbody {
        padding-right: 120px;
        input[type="submit"] {
            width: 110px;
        }

        &.icon {
            padding-right: 55px;
            input[type="submit"] {
                width: 45px;
                font-family: 'FontAwesome';
                //icon im Layout festgelegt
            }
        }
    }
}





// --------------------------------------------------------------------------------------------------------
// ---                                                                                                  ---
// ---                                             UNFERTIG                                             ---
// ---                                                                                                  ---
// --------------------------------------------------------------------------------------------------------



// ----------------------------------------------------
// --------------------- COLORBOX  --------------------
// ----------------------------------------------------

#cboxPrevious,
#cboxNext,
#cboxClose {
	z-index: 100;
	background: url(../img/gallery-controls2.png) no-repeat top right;

	&:focus {
		outline: none;
	}
}

#cboxWrapper {
    #wrapper {
        padding: 10px;
    }

    .mod_article {
        padding: 30px;
        max-width: none!important;
    }
}

#cboxNext,
#cboxPrevious {
	width: 45px !important;
	opacity: 0.8;
	transition: all 0.2s ease-in-out;


    &:hover {
        opacity: 1;
    }

}
#cboxNext {
	right: 0px!important;
	height: 93px !important;
	margin-top: -46px !important;
	background-position-x: -130px;
	&:hover {
		background-position-x: -155px;
	}
}
#cboxPrevious {
	left: 0px!important;
	height: 93px !important;
	margin-top: -46px !important;
	background-position-x: -25px;
	&:hover {
		background-position-x: 0px;
	}
}
#cboxClose {
	width: 34px!important;
	height: 34px!important;
	top: -34px!important;
	right: 0px!important;
	background-position: top center !important;

    &:hover {
        background-position: top center!important;
    }
}

#cboxTitle {
	top: -24px;
	font-size: 13px;
}

#cboxContent {
	margin-top: 25px!important;
}


#cboxLoadedContent {
	width:auto!important;
	border: 0!important;

	#main {
		padding: 0!important; }
	p.back {
		display: none;
	}
	.container {
		padding: 50px;
		width: 90%!important;
	}
	#wrapper {
		width:600px;
		margin: 0 auto;
	}
}



#cboxCurrent {
	display: none!important;
}

body.no_scroll {
	overflow: hidden;
}

#cboxOverlay {
	opacity: 0.8!important;
}





// Schrott??

// -------------------- Datepicker --------------------

.input-date {
  max-width: 150px;
  margin-left: 15px;
  float: left;
  margin-right: 15px;
}

.datepicker_bootstrap {
  font-size: 14px;
}

// ----------------------------------------------------
// ----------- COOKIE-CONSENT USERCENTRICS  -----------
// ----------------------------------------------------


#uc-banner-centered {
   &.uc-border-radius-small {
       border-radius: 0px;
   }
   .uc-banner-content {
       .uc-banner-btn-list {
           .uc-btn-accept-wrapper,
           .uc-btn-deny-wrapper {
               margin: 0px 0px 5px !important;
           }
       }
   }
}
#usercentrics-button {
   .uc-banner-content {
       background-color: $lightgray4 !important;
   }
   .uc-banner-text {
       #uc-full-optin-description {
           font-family: "Open Sans", sans-serif !important;
       }
   }
   .uc-btn-new {
       border-radius: 0px !important;
       font-family: "Open Sans", sans-serif !important;
       &.uc-btn-accept {
           background-color: $color1 !important;
           color: #fff  !important;
           transition: all 0.4s ease-in-out;
           &:hover {
               background-color: $gray6 !important;
           }
       }
       &.uc-btn-deny,
       &.uc-btn-more	{
           background-color: $color1 !important;
           &:hover {
               background-color: $gray6 !important;
           }
       }
   }
}

// ----------------------------------------------------
// -------------------- KEYFRAMES  --------------------
// ----------------------------------------------------
@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

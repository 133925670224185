/* cinzel-decorative-regular - latin */
@font-face {
  font-family: 'Cinzel Decorative';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('../fonts/cinzel_decorative/cinzel-decorative-v12-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/cinzel_decorative/cinzel-decorative-v12-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* cinzel-decorative-700 - latin */
@font-face {
  font-family: 'Cinzel Decorative';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('../fonts/cinzel_decorative/cinzel-decorative-v12-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/cinzel_decorative/cinzel-decorative-v12-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* cinzel-decorative-900 - latin */
@font-face {
  font-family: 'Cinzel Decorative';
  font-style: normal;
  font-weight: 900;
  src: local(''),
       url('../fonts/cinzel_decorative/cinzel-decorative-v12-latin-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/cinzel_decorative/cinzel-decorative-v12-latin-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
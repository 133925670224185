
// ------------------------------------------------------------- Grow -------------------------------------------------------------
.effect-grow {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-webkit-transition-property: transform;
	transition-property: transform;

	&:hover, &:focus, &:active {
		-webkit-transform: scale(1.05);
		transform: scale(1.05);
	}
}


// ------------------------------------------------------------- Pulse -------------------------------------------------------------
@-webkit-keyframes effect-pulse {
	to {
		-webkit-transform: scale(1.05);
		transform: scale(1.05);
	}
}
@keyframes effect-pulse {
	to {
		-webkit-transform: scale(1.05);
		transform: scale(1.05);
	}
}
.effect-pulse {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);

	&:hover, &:focus, &:active {
		-webkit-animation-name            : effect-pulse;
		animation-name                    : effect-pulse;
		-webkit-animation-duration        : 0.3s;
		animation-duration                : 0.3s;
		-webkit-animation-timing-function : linear;
		animation-timing-function         : linear;
		-webkit-animation-iteration-count : infinite;
		animation-iteration-count         : infinite;
		-webkit-animation-direction       : alternate;
		animation-direction               : alternate;
	}
}

// ------------------------------------------------------------- Pop -------------------------------------------------------------
@-webkit-keyframes effect-pop {
  50% {
    -webkit-transform : scale(1.1);
    transform         : scale(1.1);
  }
}
@keyframes effect-pop {
  50% {
    -webkit-transform : scale(1.1);
    transform         : scale(1.1);
  }
}
.effect-pop {
	display           : inline-block;
	vertical-align    : middle;
	-webkit-transform : perspective(1px) translateZ(0);
	transform         : perspective(1px) translateZ(0);
	box-shadow        : 0 0 1px rgba(0, 0, 0, 0);

	&:hover, &:focus, &:active {
		-webkit-animation-name            : effect-pop;
		animation-name                    : effect-pop;
		-webkit-animation-duration        : 0.3s;
		animation-duration                : 0.3s;
		-webkit-animation-timing-function : linear;
		animation-timing-function         : linear;
		-webkit-animation-iteration-count : 1;
		animation-iteration-count         : 1;
	}
}


// ------------------------------------------------------------- Bounce -------------------------------------------------------------
.effect-bounce {
	display                     : inline-block;
	vertical-align              : middle;
	-webkit-transform           : perspective(1px) translateZ(0);
	transform                   : perspective(1px) translateZ(0);
	box-shadow                  : 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration : 0.5s;
	transition-duration         : 0.5s;

	&:hover, &:focus, &:active {
		-webkit-transform                  : scale(1.1);
		transform                          : scale(1.1);
		-webkit-transition-timing-function : cubic-bezier(0.47, 2.02, 0.31, -0.36);
		transition-timing-function         : cubic-bezier(0.47, 2.02, 0.31, -0.36);
	}
}
